import React from 'react';
import { Fragment, cloneElement } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput,
    SelectInput,
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import rowStyle from './rowStyle';
import { makeStyles } from '@material-ui/core/styles';

import BulkConfirmButton from './BulkConfirmButton';
import BulkRejectButton from './BulkRejectButton';
import BulkDeleteButton from './BulkDeleteButton';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment  } from '@material-ui/core';

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const AccountFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
        <TextInput
            alwaysOn
            resettable
            helperText={false}
            source="accountName"
            label="Search Account Name"
            InputProps={{
                endAdornment: (
                <InputAdornment>
                    <SearchIcon color="disabled" />
                </InputAdornment>
                )
            }}
        />
        <NullableBooleanInput source="isActive" label="IsActive" 
            options={{ 
                SelectProps: { displayEmpty: true },
                InputLabelProps: { shrink: true }
            }}/>
        <SelectInput
            source="status"
            choices={[
                { id: 'Confirmed', name: 'Confirmed' },
                { id: 'Unconfirmed', name: 'Unconfirmed' },
                { id: 'Rejected', name: 'Rejected' },
            ]}
            className={classes.status}
        />
        <SelectInput
            label="Type"
            source="accountType"
            choices={[
                { id: 'Individual', name: 'Individual' },
                { id: 'Group', name: 'Group' },
            ]}
        />
        <ReferenceInput
            filter={{isActive: true, pagination: false}} 
            label="Network Leader"
            source="leader" reference="leaders"
            filterToQuery={searchText => ({ leaderName: searchText })}
            sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="leaderName" resettable/>
        </ReferenceInput>
    </Filter>
    )
};

const ListBulkActionButtons = (props) => {
    return (
        <Fragment>
            <BulkConfirmButton {...props} />
            <BulkRejectButton {...props} />
            <BulkDeleteButton {...props} />
        </Fragment>
    )
};

const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <RefreshButton />
        </TopToolbar>
    );
};

const EmailMobileField = (props) => {

    return (
        <>
        <div>
            <TextField source="accountMobile" {...props}/>
        </div>
        
        <div>
            <EmailField  source="accountEmail" {...props}/>
        </div>
        </>
    )
};

EmailMobileField.defaultProps = { label: 'Email/Mobile' };

export const styles = {
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    name: { width: '25%' },
    type: { width: '10%'},
    address: { width: '20%' },
    status: { width: '10%', textAlign: 'center' },
    isActive: { width: '5%', textAlign: 'center' },
    leader: { width: '20%' },
    // mobileNo: { width: '10%'},
    // createdAt: { width: '15%', textAlign: 'center' },
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    },
};

const useStyles = makeStyles(styles);
const AccountList = (props) => {
    const { 
        selectedRow
    } = props;

    const classes = useStyles();

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Accounts"
                bulkActionButtons={<ListBulkActionButtons />}
                actions={<ListActions/>}
                filters={<AccountFilter />}
                perPage={25}
                sort={{ field: 'createdAt', order: 'DESC' }}>
                <Datagrid 
                    optimized 
                    rowClick="toggleSelection"
                    rowStyle={rowStyle(selectedRow)}
                    classes={{
                        headerRow: classes.headerRow,
                        headerCell: classes.headerCell,
                        rowCell: classes.rowCell,
                    }}
                    >
                    <TextField source="accountType" label="Account Type" cellClassName={classes.type}/>
                    <TextField source="accountName" label="Account Name" cellClassName={classes.name}/>
                    
                    {/* <EmailMobileField cellClassName={classes.email}/> */}
                    <TextField source="accountAddress" label="Address" cellClassName={classes.address}/>
                    {/* <ReferenceField cellClassName={classes.leader} sortBy="leader.leaderName" source="leader" label="Senior Pastor" reference="leaders"  >
                        <TextField source="leaderName"/>
                    </ReferenceField> */}
                    <TextField source="leader.leaderName" label="Network Leader" cellClassName={classes.leader}/>
                    {/* <DateField source="createdAt" showTime={true} label="CreatedAt" cellClassName={classes.createdAt} headerClassName={classes.createdAt}/> */}
                    <TextField source="status" label="Status" cellClassName={classes.status} headerClassName={classes.status}/>
                    <BooleanField source="isActive" label="IsActive" cellClassName={classes.isActive} headerClassName={classes.isActive} className={classes.alignCenter}/>
                    <EditButton />
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default AccountList
