import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import {
    Button,
    Confirm,
    useRefresh,
    useNotify,
    TextInput,
    minLength,
    required,
    useUpdate,
    useDataProvider
} from 'react-admin';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(
    theme => ({
        cancelButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        cancelLabel: {
            color: theme.palette.error.main,
        }
    }),
);

const CancelButton = ({ record }) => {
    const classes = useStyles();

    const notify = useNotify();

    const [showDialog, setShowDialog] = useState(false);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {

        setLoading(true);
        dataProvider.update('payment-collections', { 
            id: record.id, 
            data: { status: 'Cancelled', isCancelled: true, cancelledReason: values.cancelledReason },
            record
        })
        .then(({ data }) => {
            console.log(data)
            notify('Successfully cancelled');
            setLoading(false);
            setShowDialog(false);
        })
        .catch(error => {
            notify('Opss! something went wrong', 'error');
            setLoading(false);
        })
    };

    return (
        <>
            { record?.isCancelled && 
                <Typography variant="h5" className={classes.cancelLabel}>
                    {record.remarks}
                </Typography>
            }
            { !record?.isCancelled && 
                <Button
                    className={classes.cancelButton}
                    label="Cancel this payment"
                    onClick={handleClick}
                >
                    <CancelIcon/>
                </Button>
            }
            
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={showDialog}
                onClose={handleCloseClick}
            >
                <Form
                    onSubmit={handleSubmit}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>Cancel Payment?</DialogTitle>
                            <DialogContent>
                                <TextInput 
                                    fullWidth
                                    source="cancelledReason" 
                                    rowsMax={15}
                                    rows={5} 
                                    multiline 
                                    label="Reason" 
                                    validate={[required(), minLength(3)]}
                                />
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="No, Cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="Yes, Continue"
                                    type="submit"
                                    disabled={loading}
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
            </Dialog>
        </>
      
    )
}

export default CancelButton
