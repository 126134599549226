import OfferingCreate from './OfferingCreate';
import OfferingEdit from './OfferingEdit';
import OfferingList from './OfferingList';

const Offering = {
    list: OfferingList,
    create: OfferingCreate,
    edit: OfferingEdit
}

export default Offering;