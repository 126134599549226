import * as React from 'react';
import NumberFormat from 'react-number-format';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import CardWithIcon from './CardWithIcon';


const TotalPendingCollection = ({ value }) => {
    return (
        <CardWithIcon
            icon={AccountBalanceIcon}
            title={'Pending Collections'}
            subtitle={<NumberFormat prefix="₱" decimalScale={2} value={value} displayType={'text'} thousandSeparator={true}/>}
            to=""
        />
    );
};

export default TotalPendingCollection;