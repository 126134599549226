import React, { useCallback, useState } from 'react'
import {
    required,
    Button,
    DateInput,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    FormDataConsumer,
    usePermissions,
    useGetIdentity
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { Typography, Box } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Title } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form } from 'react-final-form';
import { hasRole } from '../../security/helper';
import { withStyles } from '@material-ui/core';

const reportBaseUrl = process.env.REACT_APP_REPORT_BASE_URL || '';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 500,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));


const AutocompleteInputInDialog = withStyles({
    suggestionsContainer: { zIndex: 2000 },
})(AutocompleteInput);

const Report = () => {
    const classes = useStyles();

    
    const [showCollectionSummaryDialog, setShowCollectionSummaryDialog] = useState(false);
    const [showCollectionSummaryLeaderDialog, setShowCollectionSummaryLeaderDialog] = useState(false);
    const [showCollectionReportLeaderDialog, setShowCollectionReportLeaderDialog] = useState(false);
    const [showCollectionReportAccountDialog, setShowCollectionReportAccountDialog] = useState(false);

    const { permissions } = usePermissions();
    const { identity, loading: identityLoading } = useGetIdentity();

    const onClickAccount = () => {
        const params = {
            status: '',
        };
    
        const queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');

        popupCenter(reportBaseUrl + '/account-list/index.php?'+queryString,
        'Runner List', 900, 600);
    }

    const onClickLeader = () => {
        popupCenter(reportBaseUrl + '/leader-list/index.php',
        'Runner List', 900, 600);
    }

    const onClickRunner = () => {
        popupCenter(reportBaseUrl + '/runner-list/index.php',
        'Runner List', 900, 600);
    }

            
    const handleShowCollectionReport = useCallback(
        event => {
            event.preventDefault();
            setShowCollectionSummaryDialog(true);
        },
        [setShowCollectionSummaryDialog]
    );

    const handleCloseCollectionReport = useCallback(() => {
        setShowCollectionSummaryDialog(false);
    }, [setShowCollectionSummaryDialog]);

    const handleShowCollectionSummaryLeader = useCallback(
        event => {
            event.preventDefault();
            setShowCollectionSummaryLeaderDialog(true);
        },
        [setShowCollectionSummaryLeaderDialog]
    );

    const handleCloseCollectionSummaryLeader = useCallback(() => {
        setShowCollectionSummaryLeaderDialog(false);
    }, [setShowCollectionSummaryLeaderDialog]);

    const handleShowCollectionReportAccount = useCallback(
        event => {
            event.preventDefault();
            setShowCollectionReportAccountDialog(true);
        },
        [setShowCollectionReportAccountDialog]
    );

    const handleCloseCollectionReportAccount = useCallback(() => {
        setShowCollectionReportAccountDialog(false);
    }, [setShowCollectionReportAccountDialog]);

    const handleShowCollectionReportLeader = useCallback(
        event => {
            event.preventDefault();
            setShowCollectionReportLeaderDialog(true);
        },
        [setShowCollectionReportLeaderDialog]
    );

    const handleCloseCollectionReportLeader = useCallback(() => {
        setShowCollectionReportLeaderDialog(false);
    }, [setShowCollectionReportLeaderDialog]);

    const popupCenter = (url, title, w, h) => {

        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : 0;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : 0;
        let width = 0;
        let height = 0;

        width = window.innerWidth ? window.innerWidth :
          document.documentElement.clientWidth ? document.documentElement.clientWidth : Screen.width;
        height = window.innerHeight ? window.innerHeight :
          document.documentElement.clientHeight ? document.documentElement.clientHeight : Screen.height;

        const left = ((width / 2) - (w / 2)) + dualScreenLeft;
        const top = ((height / 2) - (h / 2)) + dualScreenTop;
        const newWindow = window.open(url, title,
          'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        // Puts focus on the newWindow
        if (window.focus) {
            newWindow.focus();
        }
    }

    const handleCollectionSummarySubmit = async values => {
        const params = {
            start_date: values.start_date,
            end_date: values.end_date
        };
    
        const queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');

        popupCenter(reportBaseUrl + '/collection-summary/index.php?'+queryString,
        'Collection Summary', 900, 600);
    };

    const handleCollectionSummaryLeaderSubmit = async values => {
        const params = {
            leader: values.leader.split('/')[3],
            start_date: values.start_date,
            end_date: values.end_date
        };
    
        const queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');

        popupCenter(reportBaseUrl + '/collection-summary-leader/index.php?'+queryString,
        'Collection Summary (Network Leader)', 900, 600);
    };

    const handleCollectionReportLeaderSubmit = async values => {
        const params = {
            leader: values.leader.split('/')[3],
            start_date: values.start_date,
            end_date: values.end_date
        };
    
        const queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');

        popupCenter(reportBaseUrl + '/collection-report-leader/index.php?'+queryString,
        'Collection Report (Network Leader)', 900, 600);
    };

    const handleCollectionReportAccountSubmit = async values => {
        const params = {
            account: values.account.split('/')[3],
            start_date: values.start_date,
            end_date: values.end_date
        };
    
        const queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');

        popupCenter(reportBaseUrl + '/collection-report-account/index.php?'+queryString,
        'Collection Report (Account)', 900, 600);
    };

    const onClickScheduleCash = () => {
        popupCenter(reportBaseUrl + '/schedule-cash-receipts-disbursement/index.php',
        'Runner List', 900, 600);
    }

    return (
        <>
            <Title title={'Reports'} />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Available Reports
                    </ListSubheader>
                }
                className={classes.root}
                >
                {
                    (hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN")) &&
                    <>
                        <ListItem button onClick={handleShowCollectionReport}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Summary (All Leader)" />
                        </ListItem>
                        <ListItem button onClick={handleShowCollectionSummaryLeader}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Summary By Leader" />
                        </ListItem>
                        <ListItem button onClick={handleShowCollectionReportLeader}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Report By Leader" />
                        </ListItem>
                        <ListItem button onClick={handleShowCollectionReportAccount}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Report By Account" />
                        </ListItem>
                        <ListItem button onClick={onClickScheduleCash}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schedule Cash Receipts/Disbursement" />
                        </ListItem>
                        <ListItem button onClick={onClickAccount}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="List of Accounts" />
                        </ListItem>
                        <ListItem button  onClick={onClickLeader}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="List of Leaders" />
                        </ListItem>
                        <ListItem button  onClick={onClickRunner}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="List of Runners" />
                        </ListItem>
                    </>
                }


                {
                    
                    (hasRole(permissions, "ROLE_LEADER")) &&
                    <>
                        <ListItem button onClick={handleShowCollectionSummaryLeader}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Summary By Leader" />
                        </ListItem>
                        <ListItem button onClick={handleShowCollectionReportLeader}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Report" />
                        </ListItem>
                        <ListItem button onClick={handleShowCollectionReportAccount}>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Report By Account" />
                        </ListItem>
                    </>
                }
                
            </List>

            <Dialog
                fullWidth
                open={showCollectionSummaryDialog}
                maxWidth={'xs'}
                onClose={handleCloseCollectionReport}
                scroll={'body'}
                disableBackdropClick={true}
                disableEnforceFocus 
            >
                <DialogContent style={{padding: '0px'}}>
                <Form
                    onSubmit={handleCollectionSummarySubmit}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>Collection Summary (All Leader)</DialogTitle>
                            <DialogContent>
                                <DateInput fullWidth source="start_date" label="Date From" validate={[required()]}/>
                                <DateInput fullWidth source="end_date" label="Date To" validate={[required()]}/>
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="Cancel"
                                    onClick={handleCloseCollectionReport}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="OK"
                                    type="submit"
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
                </DialogContent>
            
            </Dialog>


            <Dialog
                fullWidth
                open={showCollectionSummaryLeaderDialog}
                maxWidth={'xs'}
                onClose={handleCloseCollectionSummaryLeader}
                scroll={'body'}
                disableBackdropClick={true}
                disableEnforceFocus 
            >
                <DialogContent style={{padding: '0px'}}>
                <Form
                    initialValues={hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN") ? {} : { leader: '/api/leaders/' + identity?.leaderId }}
                    onSubmit={handleCollectionSummaryLeaderSubmit}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>Collection Summary By Leader</DialogTitle>
                            <DialogContent>
                                <ReferenceInput required fullWidth 
                                    filter={{isActive: true, pagination: false}}
                                    sort={{ field: 'id', order: 'ASC' }}
                                    label="Network Leader" source="leader" reference="leaders" validate={[required()]}>
                                    <SelectInput optionText="leaderName" disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN") ? false : true}/>
                                </ReferenceInput>
                                <DateInput fullWidth source="start_date" label="Date From" validate={[required()]}/>
                                <DateInput fullWidth source="end_date" label="Date To" validate={[required()]}/>
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="Cancel"
                                    onClick={handleCloseCollectionSummaryLeader}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="OK"
                                    type="submit"
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
                </DialogContent>
            
            </Dialog>

            <Dialog
                fullWidth
                open={showCollectionReportLeaderDialog}
                maxWidth={'xs'}
                onClose={handleCloseCollectionReportLeader}
                scroll={'body'}
                disableBackdropClick={true}
                disableEnforceFocus 
            >
                <DialogContent style={{padding: '0px'}}>
                <Form
                    onSubmit={handleCollectionReportLeaderSubmit}
                    
                    initialValues={hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN") ? {} : { leader: '/api/leaders/' + identity?.leaderId }}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>Collection Report By Leader</DialogTitle>
                            <DialogContent>
                                <ReferenceInput required fullWidth 
                                    filter={{isActive: true, pagination: false}}
                                    sort={{ field: 'id', order: 'ASC' }}
                                    label="Network Leader" source="leader" reference="leaders" validate={[required()]}>
                                    <SelectInput optionText="leaderName" disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN") ? false : true}/>
                                </ReferenceInput>
                                <DateInput fullWidth source="start_date" label="Date From" validate={[required()]}/>
                                <DateInput fullWidth source="end_date" label="Date To" validate={[required()]}/>
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="Cancel"
                                    onClick={handleCloseCollectionReportLeader}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="OK"
                                    type="submit"
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
                </DialogContent>
            
            </Dialog>

            <Dialog
                fullWidth
                open={showCollectionReportAccountDialog}
                maxWidth={'xs'}
                onClose={handleCloseCollectionReportAccount}
                scroll={'body'}
                disableBackdropClick={true}
                disableEnforceFocus 
            >
                <DialogContent style={{padding: '0px'}}>
                <Form
                    onSubmit={handleCollectionReportAccountSubmit}
                    initialValues={hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN") ? {} : { leader: '/api/leaders/' + identity?.leaderId }}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>Collection Report By Account</DialogTitle>
                            <DialogContent>
                            <ReferenceInput 
                                fullWidth
                                filter={{isActive: true, pagination: false}}
                                sort={{ field: 'createdAt', order: 'ASC' }} label="Select Network Leader" source="leader" reference="leaders" validate={[required()]} 
                                formClassName={classes.leader}
                                format={v => v ? v['@id'] || v : ''} >
                                <SelectInput optionText="leaderName"  disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN") ? false : true}/>
                            </ReferenceInput>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    formData.leader && (
                                    <ReferenceInput
                                        fullWidth
                                        filter={{isActive: true, status: 'Confirmed', leader: formData.leader}} 
                                        label="Account"
                                        source="account" reference="accounts"
                                        filterToQuery={searchText => ({ accountName: searchText })}
                                        sort={{ field: 'id', order: 'ASC' }}
                                        validate={[required()]} 
                                        resettable>
                                        <AutocompleteInputInDialog optionText="accountName"/>
                                    </ReferenceInput>
                                    )
                                )}
                            </FormDataConsumer>
                                <DateInput fullWidth source="start_date" label="Date From" validate={[required()]}/>
                                <DateInput fullWidth source="end_date" label="Date To" validate={[required()]}/>
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="Cancel"
                                    onClick={handleCloseCollectionReportAccount}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="OK"
                                    type="submit"
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
                </DialogContent>
            
            </Dialog>
        </>
         
    )
}

export default Report
