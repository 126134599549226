import React from 'react'
import { 
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    minLength,
    Toolbar,
    SaveButton,
    ListButton,
    TopToolbar,
    DateInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab 
} from 'react-admin'
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import forEach from 'lodash/forEach';

const entrypoint = process.env.REACT_APP_API_BASE_URL || '';

const CustomTitle = ({ record }) => {
    return record ? <span>Edit Payment Group #{record.originId}</span> : null;
}

 const CustomActions = (props) => {
     const { basePath } = props;
     return (
         <TopToolbar>
             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
         </TopToolbar>
     )
 };
 
 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Updated`, 'info', {}, true);
         redirect(false);
         refresh();
     };

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
 };

 const SectionTitle = ({ label }) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;
const BankAccountField = ({record}) => {
    return (
        <>
            <span>({record.bankCode}) {record.accountNo} - {record.accountName}</span>
        </>
    )
};
const PreviewImage = ({ record, source }) => {
    if (typeof (record.url) === "string") {
        record = {
            [source]: entrypoint + record.url
        }
    }
    return <ImageField record={record} source={source} />
}
const formatLogo = (value)  => {
    if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it 
     return { url: value };
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
      return value;
    }
}

const styles = {
    paymentDate: { display: 'inline-block', width: '25%' },
    modePayment: { display: 'inline-block',  width: '25%' },
    depositDate: { display: 'inline-block', width: '25%' },
    depositNo: { display: 'inline-block',  marginLeft: '1%', width: '25%' },
    bankAccount: { width: '51%' },
    remarks: { width: '51%' },
    attachment: { width: '51%' },

    account: { display: 'inline-block', width: '100%' },
    collectionDate: { display: 'inline-block', width: '25%' },
    amount: { display: 'inline-block',  marginLeft: '1%', width: '25%' },
    reference: { display: 'inline-block',  marginLeft: '1%', width: '25%' },
};


const useStyles = makeStyles(styles);


const WithProps = ({children,...props}) => children(props);
const PaymentEdit = (props) => {

    const classes = useStyles(props);

    const transform = data => {
        const paymentItems = [];
        forEach(data.paymentItems, (item) => {
            paymentItems.push({
                account: item.account['@id'],
                amount: item.amount,
                referenceNo: item.referenceNo,
                status: 'Active'
            });
        });
        return {
            paymentDate: data.paymentDate,
            depositDate: data.depositDate,
            depositNo: data.depositNo,
            status: data.status,
            remarks: data.remarks,
            photoRefFile: data.photoRefUrl,
            bankAccount: data.bankAccount,
            modePayment: data.modePayment,
            paymentItems: paymentItems
        }
    };
    
    return (
        <Edit title={<CustomTitle />} transform={transform} {...props} actions={<CustomActions />}>
            <WithProps>{({record,...props})=>
                <TabbedForm
                    record={record}
                    {...props}
                    toolbar={<CustomToolbar />}>
                    <FormTab
                        label="Deposit Slip Info"
                    >
                        {/* <DateInput 
                            disabled={record.isPosted}
                            fullWidth
                            source="paymentDate" 
                            label="Payment Date" 
                            validate={[required()]}
                            formClassName={classes.paymentDate}
                        /> */}
                        <SelectInput 
                            fullWidth
                            formClassName={classes.modePayment}
                            label="Mode of Payment"
                            source="modePayment" 
                            resettable={true} 
                            choices={[
                            { id: 'CSH', name: 'Cash' },
                            { id: 'CHK', name: 'Check' },
                            { id: 'BNT', name: 'Bank Transfer' }
                        ]} validate={[required()]}/>
                        <ReferenceInput required 
                            fullWidth
                            filter={{isActive: true, pagination: false}}
                            sort={{ field: 'createdAt', order: 'ASC' }}
                            abel="Select Bank Account" source="bankAccount" 
                            reference="bank-accounts" validate={[required()]}
                            formClassName={classes.bankAccount}>
                            <SelectInput optionText={<BankAccountField />} />
                        </ReferenceInput>
                        <DateInput 
                            fullWidth
                            source="depositDate" 
                            label="Deposit Date" 
                            validate={[required()]}
                            formClassName={classes.depositDate}
                        />
                        <TextInput 
                            fullWidth
                            source="depositNo" 
                            label="Deposit Slip No." 
                            validate={[required()]}
                            formClassName={classes.depositNo}
                        />
                        <ImageInput 
                            validate={[required()]}
                            format={ formatLogo }
                            formClassName={classes.attachment} 
                            source="photoRefUrl" 
                            label="Attach deposit slip" 
                            accept="image/png, image/jpg, image/jpeg"
                            placeholder={<p>Drag 'n' drop image here, or click to select files</p>}>
                            <PreviewImage source="src" />
                        </ImageInput>
                        <TextInput 
                            disabled={record.isPosted}
                            fullWidth
                            source="remarks" 
                            rowsMax={15}
                            rows={5} 
                            multiline 
                            label="Remarks" 
                            validate={minLength(3)}
                            formClassName={classes.remarks}
                        />
                        
                        {/* <SelectInput 
                            fullWidth
                            source="status" 
                            choices={[
                                { id: 'Active', name: 'Active' },
                                { id: 'Posted', name: 'Posted' },
                                { id: 'Cancelled', name: 'Cancelled' },
                            ]} 
                            validate={[required()]} 
                            formClassName={classes.status}
                        /> */}
                    </FormTab>
                    
                    <FormTab
                        path="payment-items"
                        label="Payment Details"
                    >
                        <ArrayInput source="paymentItems" label="Payment Details" style={{width: '100%'}}>
                            <SimpleFormIterator disableRemove={record.isPosted} disableAdd={record.isPosted}>
                            
                                <ReferenceInput
                                    disabled={record.isPosted}
                                    validate={[required()]}
                                    fullWidth
                                    allowEmpty
                                    resettable
                                    label="Select account..."
                                    filter={{isActive: true, status: 'Confirmed'}} 
                                    source='account' reference="accounts"
                                    filterToQuery={searchText => ({ accountName: searchText })}
                                    formClassName={classes.account}
                                    format={v => v ? v['@id'] || v : ''}
                                >
                                    <AutocompleteInput
                                        optionText="accountName"
                                    />
                                </ReferenceInput>
                                <DateInput 
                                    fullWidth
                                    source="paymentDate" 
                                    label="Collection Date" 
                                    validate={[required()]}
                                    formClassName={classes.collectionDate}
                                />
                                <TextInput 
                                    fullWidth
                                    source="referenceNo" 
                                    label="OR No." 
                                    validate={[required()]}
                                    formClassName={classes.reference}
                                />
                                <NumberInput
                                    disabled={record.isPosted}
                                    validate={[required()]}
                                    fullWidth
                                    source="amount" 
                                    label="Amount"
                                    formClassName={classes.amount}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                </TabbedForm> }
            </WithProps>
        </Edit>
    )
}

export default PaymentEdit
