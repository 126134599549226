import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    Toolbar,
    SaveButton,
    useCreateController,
    CreateContextProvider 
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import { makeStyles } from '@material-ui/core';
 
 import FromDialog from './FormDialog';

 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Added`, 'info');
         redirect('list', basePath);
         refresh();
     };
     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
};

const useStyles = makeStyles({
    bankCode: { width: '50%' },
    accountNo: { width: '50%' },
});

const BankAccountCreate = ({ onCancel, ...props}) => {
    const classes = useStyles();

    const controllerProps  = useCreateController(props);
 
    return (
        <FromDialog onCancel={onCancel} title="Add Bank Account">
            <CreateContextProvider value={controllerProps}>
                <SimpleForm 
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomToolbar />}
                >
                    <TextInput fullWidth autoFocus source="bankCode" label="Bank Code" validate={[required()]} formClassName={classes.bankCode}/>
                    <TextInput fullWidth source="accountNo" label="Account No." validate={[required()]} formClassName={classes.accountNo}/>
                    <TextInput fullWidth source="accountName" label="Account Name" validate={[required()]}/>
                    <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
                </SimpleForm>
            </CreateContextProvider>
        </FromDialog>
    )
}

export default BankAccountCreate
