import React from 'react';
import { Fragment, cloneElement } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    NullableBooleanInput,
    SelectInput,
    useListContext,
    SearchInput
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';


const TemplateFilter = props => (
    <Filter {...props}>
        <SearchInput source="label" alwaysOn placeholder="Search Template Name"/>
        <NullableBooleanInput source="isActive" label="IsActive"
        options={{ 
            SelectProps: { displayEmpty: true },
            InputLabelProps: { shrink: true }
        }}/>
        <SelectInput
                source="type"
                choices={[
                    { id: 'SMS', name: 'SMS' },
                    { id: 'EMAIL', name: 'EMAIL' },
                ]}
            />
    </Filter>
);

const ListActions = (props) => {
    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <RefreshButton />
        </TopToolbar>
    );
};

export const styles = {
    isActive: { width: '10%', textAlign: 'center' },
    name: { width: '60%' },
    description: { width: '30%'},
    type: { width: '10%', textAlign: 'center' },
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    }
};

const useStyles = makeStyles(styles);
const TemplateList = (props) => {
    const { 
        basePath
    } = props;

    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info', {},true);
        redirect('list', basePath);
        refresh();
    }

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Templates"
                actions={<ListActions/>}
                filters={<TemplateFilter />}
                perPage={25}
                sort={{ field: 'id', order: 'DESC' }}>
                <Datagrid>
                    <TextField source="label" label="Template Name" cellClassName={classes.name}/>
                    <TextField source="type" label="Type" cellClassName={classes.type} headerClassName={classes.type}/>
                    <BooleanField source="isActive" label="IsActive" cellClassName={classes.isActive} headerClassName={classes.isActive} className={classes.alignCenter}/>
                    <EditButton />
                    <DeleteButton onSuccess={onSuccessDelete}/>
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default TemplateList
