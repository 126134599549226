import * as React from 'react';
import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';

import {
    Button,
    useUpdateMany,
    useNotify,
    useRefresh,
    CRUD_UPDATE_MANY,
    Confirm,
    useResourceContext
} from 'react-admin';

const noSelection = [];

const BulkReviewButton = ({
    unselectAll,
    selectedIds = noSelection,
    ...props
}) => {
    
    const [open, setOpen] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();
    const resource = useResourceContext(props);

    const [updateMany, { loading }] = useUpdateMany(
        resource,
        selectedIds,
        { status: 'Posted', isPosted: true },
        {
            action: CRUD_UPDATE_MANY,
            mutationMode: 'pessimistic',
            onSuccess: () => {
                const message = selectedIds.length === 1
                ? `1 item successfully posted`
                : `${selectedIds.length} items successfully posted`;

                setOpen(false);
                refresh();
                notify(
                    message,
                    'info'
                );
                unselectAll();
          
            },
            onFailure: () => {
                notify(
                    'Error: Selected deposit not posted',
                    'warning'
                );
            },
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = useCallback(() => {
        updateMany();
    },[updateMany]);

    return (
        <>
            <Button
                label="Post"
                onClick={handleClick}
                disabled={loading}
            >
                <LibraryAddCheckIcon />
            </Button>
            <Confirm
                loading={loading}
                isOpen={open}
                title='Set As Posted'
                content="Are you sure you want to do this?"
                confirm="Yes, Continue"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

BulkReviewButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkReviewButton;