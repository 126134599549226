import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useQueryWithStore, Loading } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DatagridLoading  from '../../shared/DatagridLoading';

import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
    root: { width: '90%', margin: 'auto', padding: '25px 0px' }
});

const PaymentCollectionView = ({ record, id}) => {
    const classes = useStyles();

    const { data, total, loaded, loading, error } = useQueryWithStore({
        type: 'getList',
        resource: 'payment-collections',
        payload: {
            pagination: { page: 1, pageSize: 25 },
            sort: { field: 'referenceNo', order: 'ASC' },
            filter: { deposit: id, pagination: false},
        }
    });
    
    if (!loaded) { return (
        <div className={classes.root}>
            <DatagridLoading nbChildren={6}/>
        </div>
    ); }

    const computeTotalAmount = (items) => {
        return items.map(({ paidAmount }) => paidAmount).reduce((sum, i) => sum + i, 0);
    }

    const totalAmount = computeTotalAmount(data);

    return (
        <div className={classes.root}>
            <Typography  variant="body1" gutterBottom>
                <b>Remarks :</b> {record.remarks}
            </Typography>
            
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Runner</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell align="center">OR/Collected Date</TableCell>
                        <TableCell align="center">Reference No</TableCell>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((row, i) => (
                        <TableRow  key={i}>
                            <TableCell  component="th" scope="row">
                                {row.runner?.runnerName}
                            </TableCell >
                            <TableCell  >{row.account?.accountName}</TableCell >
                            <TableCell  align="center">{moment(row.collectedDate).format('YYYY-MM-DD')}</TableCell >
                            <TableCell  align="center">{row.referenceNo}</TableCell >
                            <TableCell  align="center">{row.type}</TableCell >
                            <TableCell  align="right">{<NumberFormat value={row.paidAmount} displayType={'text'} thousandSeparator={true} />}</TableCell >
                        </TableRow >
                    ))}
                    <TableRow >
                        <TableCell  colSpan={5} align="right"><b>TOTAL AMOUNT</b></TableCell >
                        <TableCell  align="right"><b>{<NumberFormat value={totalAmount} displayType={'text'} thousandSeparator={true} />}</b></TableCell >
                        
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default PaymentCollectionView
