import * as React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    useDeleteMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    CRUD_DELETE_MANY,
    useResourceContext
} from 'react-admin';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

import ActionDelete from '@material-ui/icons/Delete';

const useStyles = makeStyles(
    theme => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaBulkDeleteWithUndoButton' }
);

const noSelection = [];
const BulkDeleteButton = ({
    selectedIds = noSelection,
    ...props
}) => {
    const {
        label,
        icon
    } = props;
    
    const classes = useStyles();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const resource = useResourceContext(props);
    const [deleteMany, { loading }] = useDeleteMany(
        resource, 
        selectedIds, 
        {
            action: CRUD_DELETE_MANY,
            undoable: true,
            onSuccess: () => {
                const message = selectedIds.length === 1
                                ? `1 item successfully deleted`
                                : `${selectedIds.length} items successfully deleted`;
                notify(
                    message, 
                    'info', 
                    {
                        smart_count: selectedIds.length,
                    }, 
                    true
                );
                unselectAll(resource);
                refresh();
            },
            onFailure: (error) => {
                notify(
                    'Error! Selected account not deleted',
                    'warning'
                );
            },
        }
    );

    return (
        <Button  
            onClick={deleteMany}
            label={label}
            className={classes.deleteButton} 
            disabled={loading}
        >
            {icon}
        </Button >
    );
};

BulkDeleteButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

BulkDeleteButton.defaultProps = {
    label: 'Delete',
    icon: <ActionDelete />,
};

export default BulkDeleteButton;