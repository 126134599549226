import React from 'react';
import { Fragment, useCallback, cloneElement } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    useListContext,
    SearchInput,
    ReferenceArrayField,
    ChipField,
    SingleFieldList
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { makeStyles } from '@material-ui/core/styles';

import BankAccountCreate from './BankAccountCreate';
import BankAccountEdit from './BankAccountEdit';

const useFilterStyles = makeStyles({
    accountNo: { width: 200 },
    accountName: { width: 400 }
});

const CustomFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
            <TextInput
                id="saccountNo"
                className={classes.accountNo}
                resettable
                source="accountNo"
                label="Account No."
                alwaysOn
            />
            <TextInput
                id="saccountName"
                className={classes.accountName}
                resettable
                source="accountName"
                label="Account Name"
            />
        </Filter>
    )
};

const ListActions = (props) => {
    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
            <RefreshButton />
        </TopToolbar>
    );
};

const styles = {
    isActive: { width: '10%', textAlign: 'center' },
    bankCode: { width: '15%' },
    accountNo: { width: '15%' },
    accountName: { width: '40%' },
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    }
};

const useStyles = makeStyles(styles);
const LeaderList = (props) => {
    const { 
        basePath
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info', {},true);
        redirect('list', basePath);
        refresh();
    }

    const handleClose = useCallback(() => {
        history.push('/bank-accounts');
    }, [history]);

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Bank Accounts"
                actions={<ListActions/>}
                filters={<CustomFilter />}
                perPage={25}
                sort={{ field: 'id', order: 'DESC' }}>
                <Datagrid>
                    <TextField source="bankCode" label="Bank Code" cellClassName={classes.bankCode}/>
                    <TextField source="accountNo" label="Account No." cellClassName={classes.accountNo}/>
                    <TextField source="accountName" label="Account Name" cellClassName={classes.accountName}/>
                    <BooleanField source="isActive" label="IsActive" cellClassName={classes.isActive} headerClassName={classes.isActive} className={classes.alignCenter}/>
                    <EditButton />
                    <DeleteButton onSuccess={onSuccessDelete}/>
                </Datagrid>
            </List>
            <Route path="/bank-accounts/create">
                {({ match }) => (
                    <Dialog
                        fullWidth
                        open={!!match}
                        maxWidth={'xs'}
                        onClose={handleClose}
                        scroll={'body'}
                        disableBackdropClick={true}
                    >
                        <DialogContent style={{padding: '0px'}}>
                            <BankAccountCreate
                                onCancel={handleClose}
                                {...props}
                            />
                        </DialogContent>
                    
                    </Dialog>
                )}
            </Route>
            <Route path="/bank-accounts/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        );

                        return (
                            <Dialog
                                fullWidth
                                open={isMatch}
                                maxWidth={'xs'}
                                onClose={handleClose}
                                scroll={'body'}
                                disableBackdropClick={true}
                            >
                                <DialogContent style={{padding: '0px'}}>
                                    {isMatch ? (
                                        <BankAccountEdit
                                            id={isMatch ? decodeURIComponent(match.params.id) : null}
                                            onCancel={handleClose}
                                            {...props}
                                        />
                                    ) : (
                                        null
                                    )}
                                </DialogContent>
                            
                            </Dialog>
                        );
                    }}
                </Route>
        </Fragment>
    )
}

export default LeaderList
