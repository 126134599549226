import * as React from 'react';
import NumberFormat from 'react-number-format';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import CardWithIcon from './CardWithIcon';


const TotalExpenses = ({ value }) => {
    return (
        <CardWithIcon
            icon={AttachMoneyIcon}
            title={'Expenses'}
            subtitle={<NumberFormat prefix="₱" decimalScale={2} value={value} displayType={'text'} thousandSeparator={true}/>}
            to=""
        />
    );
};

export default TotalExpenses;