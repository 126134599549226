import React from 'react';
import { Fragment, useCallback, cloneElement, Children, useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    TopToolbar,
    RefreshButton,
    Filter,
    BooleanField,
    DateField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SelectInput,
    NumberField,
    DateInput,
    Pagination,
    NumberInput
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';


import ShowButton from './ShowButton';

const useFilterStyles = makeStyles({
    status: { width: 150 },
    accountName: { width: 350 },
    leaderName: { width: 350 }
});

const AccountFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>

            <TextInput
                alwaysOn
                label="Reference No."
                source="referenceNo"
                resettable
            />
            <ReferenceInput
                filter={{isActive: true}} 
                label="Account"
                source="account" reference="accounts"
                filterToQuery={searchText => ({ accountName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}
                className={classes.accountName}>
                <AutocompleteInput optionText="accountName"/>
            </ReferenceInput>
            <ReferenceInput
                filter={{isActive: true}} 
                label="Network Leader"
                source="leader" reference="leaders"
                filterToQuery={searchText => ({ leaderName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}
                className={classes.leaderName}>
                <AutocompleteInput optionText="leaderName"/>
            </ReferenceInput>
            <ReferenceInput
                filter={{isActive: true}} 
                label="Runner"
                source="runner" reference="runners"
                filterToQuery={searchText => ({ runnerName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}>
                <AutocompleteInput optionText="runnerName"/>
            </ReferenceInput>
            <NumberInput
                label="Paid Amount"
                source="paidAmount"
            />
            <DateInput
                label="Collected [After]"
                source="collectedDate[after]"
            />
            <DateInput
                label="Collected [Before]"
                source="collectedDate[before]"
            />
             <NullableBooleanInput source="isDeposited" label="IsDeposited" 
                options={{ 
                    SelectProps: { displayEmpty: true },
                    InputLabelProps: { shrink: true }
                }}/>
             <NullableBooleanInput source="isPosted" label="IsPosted" 
                options={{ 
                    SelectProps: { displayEmpty: true },
                    InputLabelProps: { shrink: true }
                }}/>
            <SelectInput
                source="status"
                choices={[
                    { id: 'Active', name: 'Active' },
                    { id: 'Posted', name: 'Posted' },
                    { id: 'Cancelled', name: 'Cancelled' },
                ]}
                className={classes.status}
            />
        </Filter>
    )
};


const CustomPagination = (props) => {
    const { total } = props;
    return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} count={total ? total : 0}/>;
}

const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <RefreshButton />
        </TopToolbar>
    );
};

const useListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
    
});

const ListActionToolbar = ({ children, ...props }) => {
    const classes = useListActionToolbarStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

const styles = {
    account: { width: '35%'},
    dateCollected: { width: '10%'},
    reference: { width: '10%'},
    type: {  width: '5%', textAlign: 'center' },
    status: {  width: '5%', textAlign: 'center' },
    isDeposited: { width: '5%', textAlign: 'center' },
    paidAmount: { width: '10%'},
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    },
};

const useStyles = makeStyles(styles);

const PaymentCollection = (props) => {
    const classes = useStyles();

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Payment Collections"
                bulkActionButtons={false}
                actions={<ListActions/>}
                filters={<AccountFilter />}
                perPage={25}
                sort={{ field: 'collectedDate', order: 'DESC' }}
                pagination={<CustomPagination />}>
                <Datagrid 
                    optimized
                    >
                    <TextField source="account.accountName" label="Account Name" cellClassName={classes.account} headerClassName={classes.account}/>
                    <TextField source="runner.runnerName" label="Runner" />
                    <DateField source="collectedDate" label="Date Collected" cellClassName={classes.dateCollected} headerClassName={classes.dateCollected}/>
                    <TextField source="referenceNo" label="Reference No." cellClassName={classes.reference} headerClassName={classes.reference}/>
                    <TextField source="type" label="Type" cellClassName={classes.type} headerClassName={classes.type}/>
                    <NumberField source="paidAmount" label="Paid Amount" cellClassName={classes.paidAmount} headerClassName={classes.paidAmount}/>
                    <BooleanField source="isDeposited" label="Is Deposited" cellClassName={classes.isDeposited} headerClassName={classes.isDeposited} className={classes.alignCenter}/>
                    <BooleanField source="isPosted" label="Is Posted" cellClassName={classes.isDeposited} headerClassName={classes.isDeposited} className={classes.alignCenter}/>
                    <TextField source="status" label="Status" cellClassName={classes.status} headerClassName={classes.status}/>
                    <ListActionToolbar>
                        <ShowButton/>
                    </ListActionToolbar>
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default PaymentCollection;
