import React, { useState, useEffect, cloneElement } from 'react'

import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    ReferenceInput,
    SelectInput,
    useDataProvider,
    Filter,
    SearchInput,
    NullableBooleanInput,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import { useQuery, useQueryWithStore, useListContext, Datagrid, TextField, 
    DateField, BooleanField, Pagination, Loading, ListContextProvider,
    ResourceContextProvider , ListToolbar, TopToolbar, RefreshButton, Error } from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SearchIcon from '@material-ui/icons/Search';
import { Paper, InputAdornment  } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';



const CustomFilter = props => (
    <Filter {...props}>
        <TextInput
            alwaysOn
            resettable
            helperText={false}
            source="message"
            label="Search Message"
            InputProps={{
                endAdornment: (
                <InputAdornment>
                    <SearchIcon color="disabled" />
                </InputAdornment>
                )
            }}
        />
        <TextInput source="recipient" placeholder="Recipient"/>
        <NullableBooleanInput source="isSent" label="IsSent" 
            options={{ 
                SelectProps: { displayEmpty: true },
                InputLabelProps: { shrink: true }
            }}/>
    </Filter>
);

const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <RefreshButton />
        </TopToolbar>
    );
};

export const styles = {
    isSent: { width: '10%', textAlign: 'center' },
    recipient: { width: '10%' },
    message: { width: '65%'},
    sendTime: { width: '15%', textAlign: 'center' },
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    },
};

const useStyles = makeStyles(styles);

const CustomDataGrid = (props) => {
    const classes = useStyles();
    
    return (
        <>
            <ListToolbar filters={<CustomFilter/>} actions={<ListActions />}/>
            <Paper> 
                <Datagrid >
                    <DateField source="sendTime" label="Send Date/Time" showTime={true} cellClassName={classes.sendTime} headerClassName={classes.sendTime}/>
                    <TextField source="recipient" cellClassName={classes.recipient}/>
                    <TextField source="message" cellClassName={classes.message}/>
                    <BooleanField source="isSent" label="IsSent" cellClassName={classes.isSent} headerClassName={classes.isSent} className={classes.alignCenter}/>
                </Datagrid>
                <Pagination /> 
            </Paper> 
        </>
    )
}


export const CustomList = (props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'sendTime', order: 'DESC' });
    const [currentSort, setCurrentSort] = useState({ field: 'sendTime', order: 'DESC' });
    const [filter, setFilter] = useState({});
    const [displayedFilters, setDisplayedFilters] = useState({});
    const [time, setTime] = useState(0);

    //console.log(setSort);
    const { data, total, loaded, loading, error } = useQueryWithStore({
        type: 'getList',
        resource: 'sms-logs',
        payload: {
            pagination: { page, perPage },
            sort: sort,
            filter: filter,
        }
    });

    const updateSort = (sort, order) => {
        setSort({ field: sort, order: order });
        setCurrentSort({
            field: sort,
            order: order
        });
    }
    
    const updateFilters = (filters, displayedFilters) => {
        if(time) clearTimeout(time);
        setTime(setTimeout(() => {
            setFilter(filters);
        }, 500));
    }

    const updateShowFilter = (title, defaultValue) => {
        const newFilter = {[title]: true};
        setDisplayedFilters(prevFilters => ({...prevFilters, ...newFilter }));
    }

    const updateHideFilter = (title) => {
        const newFilter = omit(filter, [`${title}`]);
        const hideFilter = omit(displayedFilters, [`${title}`]);

        setFilter(newFilter);
        setDisplayedFilters(hideFilter);
    }

    return (
        <>
            <ResourceContextProvider value="sms-logs">
                <ListContextProvider value={{
                        basePath: '/sms-logs',
                        data: keyBy(data, 'id'),
                        ids: data ? data.map(({ id }) => id) : [],
                        total,
                        loaded,
                        loading,
                        // pagination
                        page,
                        perPage,
                        setPage,
                        setPerPage,
                        // sorting
                        currentSort: currentSort,
                        setSort: updateSort,
                        // filtering
                        filterValues: filter,
                        setFilters: updateFilters,
                        displayedFilters,
                        showFilter: updateShowFilter,
                        hideFilter: updateHideFilter,
                        // row selection
                        selectedIds: [],
                        // misc
                        resource: 'sms-logs',
                }}>
                    
                    <CustomDataGrid />
                </ListContextProvider >
            </ResourceContextProvider>
       
        </>
    )
    
}
const SmsLogsButton = () => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button
                label="View Logs"
                onClick={handleClick}
            >
                <QuestionAnswerIcon/>
            </Button>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>SMS Logs</DialogTitle>
                <DialogContent style={{padding: '0px 24px'}}>
                    <CustomList />
                </DialogContent>
                <DialogActions style={{padding: '10px 24px'}}>
                    <Button
                        label="Cancel"
                        onClick={handleCloseClick}
                    >
                        <IconCancel />
                    </Button>
                </DialogActions>
               
            </Dialog>
        </>
    )
}

export default SmsLogsButton
