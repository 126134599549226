import React, {
    useState,
    useEffect,
    useCallback,
    CSSProperties,
} from 'react';
import { useVersion, useDataProvider, usePermissions } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import forEach from 'lodash/forEach';
import { hasRole } from '../../security/helper';

import Welcome from './Welcome';
import TotalConfirmedAccount from './TotalConfirmedAccount';
import TotalPendingCollection from './TotalPendingCollection';
import TotalCollection from './TotalCollection';
import TotalExpenses from './TotalExpenses';
import CollectionExpenseChart from './CollectionExpenseChart';
import DailyCollectionChart from './DailyCollectionChart';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
    const [state, setState] = useState({
        pendingCollection: 0,
        totalCollection: 0,
        expenses: 0,
        confirmedAccounts: 0,
        paymentCollections: []
    });
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    );

    

    const fetchConfirmedAccounts = useCallback(async () => {
        const { total } = await dataProvider.getList(
            'accounts',
            {
                filter: { 
                    status: 'Confirmed',
                    pagination: false, 
                },
                sort: { field: 'id', order: 'ASC' },
                pagination: { page: 1, perPage: 30 },
            }
        );

        setState(state => ({
            ...state,
            confirmedAccounts: parseFloat(total)
        }));
   
    }, [dataProvider]);

    const fetchExpenses = useCallback(async () => {
        const { data: expenses } = await dataProvider.getList(
            'expenses',
            {
                filter: { 
                    pagination: false, 
                },
                sort: { field: 'id', order: 'ASC' },
                pagination: { page: 1, perPage: 30 },
            }
        );
        
        let totalExpenses = 0;
        forEach(expenses, function(value) {
            totalExpenses += parseFloat(value.amount);
        });
     
        setState(state => ({
            ...state,
            expenses: parseFloat(totalExpenses)
        }));
    }, [dataProvider]);

    const fetchCollections = useCallback(async () => {
        const { data: collections } = await dataProvider.getList(
            'payment-collections',
            {
                filter: { 
                    pagination: false, 
                },
                sort: { field: 'id', order: 'ASC' },
                pagination: { page: 1, perPage: 30 },
            }
        );
        
        const aggregations = collections
            .filter(item => item.status !== 'Cancelled')
            .reduce(
                (stats, item) => {
                    if(item.status === 'Posted' && item.isPosted)
                        stats.totalCollection += item.paidAmount;

                    if(item.status === 'Active' && !item.isPosted)
                        stats.pendingCollection += item.paidAmount;

                    return stats;
                },
                {
                    pendingCollection: 0,
                    totalCollection: 0,
                }
            );

        setState(state => ({
            ...state,
            totalCollection: aggregations.totalCollection,
            pendingCollection: aggregations.pendingCollection,
            paymentCollections: collections
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchCollections();
        fetchConfirmedAccounts();
        fetchExpenses();
    }, [version, fetchConfirmedAccounts, fetchExpenses, fetchCollections]);

    const {
        pendingCollection,
        totalCollection,
        expenses,
        confirmedAccounts,
        paymentCollections
    } = state;

    const { permissions } = usePermissions();

    return (
        <>
            <Welcome />
            {/* <div style={styles.flex}>
                <TotalActualPledges value={1030000} />
                <Spacer />
                <TotalExpectedPledges value={2000000} />
                <Spacer />
                <TotalExpenses value={33000} />
                <Spacer />
                <TotalConfirmedAccount value={800} />
            </div> */}
            {
                (hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN")) &&

                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                                <TotalCollection value={totalCollection} />
                                <Spacer />
                                <TotalPendingCollection value={pendingCollection} />
                            </div>
                            {/* <div style={styles.singleCol}>
                                <CollectionExpenseChart />
                            </div> */}
                        </div>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <TotalExpenses value={expenses} />
                                <Spacer />
                                <TotalConfirmedAccount value={confirmedAccounts} />
                            </div>
                            {/* <div style={styles.singleCol}>
                                <DailyCollectionChart paymentCollections={paymentCollections}/>
                            </div> */}
                        </div>
                    </div>
            }
            
            {
                (hasRole(permissions, "ROLE_LEADER") ||  hasRole(permissions, "ROLE_RUNNER")) &&
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                            <div style={styles.flex}>
                                <TotalCollection value={totalCollection} />
                                <Spacer />
                                <TotalPendingCollection value={pendingCollection} />
                                <Spacer />
                                <TotalConfirmedAccount value={confirmedAccounts} />
                            </div>
                        </div>
                    </div>
            }
            <div style={styles.flexColumn }>
                <div style={styles.singleCol }>
                    <DailyCollectionChart paymentCollections={paymentCollections}/>
                </div>
            </div>
        </>
    )
};

export default Dashboard;