import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment';
import { useQueryWithStore, Loading, Confirm } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, useMutation , useRefresh, useNotify, useDataProvider } from 'react-admin';
import NumberFormat from 'react-number-format';

import DatagridLoading  from '../../shared/DatagridLoading';

import AddPaymentCollectionButton from './AddPaymentCollectionButton';

import { fade } from '@material-ui/core/styles/colorManipulator';



const PaymentCollectionTab = ({record}) => {

    const { data, total, loaded, loading, error } = useQueryWithStore({
        type: 'getList',
        resource: 'payment-collections',
        payload: {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'referenceNo', order: 'ASC' },
            filter: { deposit: record['@id'], pagination: false},
        }
    });
    
    if (!loaded) { return (
        <div style={{padding: '25px 15px'}}>
            <DatagridLoading nbChildren={6}/>
        </div>
    ); }


    const computeTotalAmount = (items) => {
        return items.map(({ paidAmount }) => paidAmount).reduce((sum, i) => sum + i, 0);
    }

    const totalAmount = computeTotalAmount(data);

    return (
        <div style={{padding: '25px 15px'}}>
            <AddPaymentCollectionButton record={record}/>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Runner</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell align="center">OR/Collected Date</TableCell>
                        <TableCell align="center">Reference No</TableCell>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((row, i) => (
                        <TableRow  key={i}>
                            <TableCell  component="th" scope="row">
                                {row.runner?.runnerName}
                            </TableCell >
                            <TableCell  >{row.account?.accountName}</TableCell >
                            <TableCell  align="center">{moment(row.collectedDate).format('YYYY-MM-DD')}</TableCell >
                            <TableCell  align="center">{row.referenceNo}</TableCell >
                            <TableCell  align="center">{row.type}</TableCell >
                            <TableCell  align="right">{<NumberFormat value={row.paidAmount} displayType={'text'} thousandSeparator={true} />}</TableCell >
                            <TableCell  align="center">
                                <DeleteButton record={row} deposit={record['@id']}/>
                            </TableCell >
                        </TableRow >
                    ))}
                    <TableRow >
                        <TableCell  colSpan={5} align="right"><b>TOTAL AMOUNT</b></TableCell >
                        <TableCell  align="right"><b>{<NumberFormat value={totalAmount} displayType={'text'} thousandSeparator={true} />}</b></TableCell >
                        <TableCell />
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(
    theme => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaBulkDeleteWithUndoButton' }
);

const DeleteButton = ({ record, deposit }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [deleteOne, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'payment-collections',
            payload: { id: record.id, data: { deposit: null, isDeposited: false}},
        },
        {
            onSuccess: () => {
               notify('Deleted successfully', 'info');
               recompute();
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = useCallback(() => {
        deleteOne();
    },[deleteOne]);

    const recompute = () => {
        dataProvider.recomputeDeposit(
            { id: deposit, data: JSON.stringify({}) }
        )
        .then(({ data }) => {
            refresh();
            handleDialogClose();
        })
        .catch(error => {
            notify('Opss! something went wrong', 'error');
        })
    }
    
    return (
        <>
            <Button
                disabled={loading}
                onClick={handleClick}
                label="Delete"
                className={classes.deleteButton} 

            >
                <DeleteIcon/>
            </Button>
            <Confirm
                loading={loading}
                isOpen={open}
                title='Confirm Delete'
                content="Are you sure you want to delete this item?"
                confirm="Confirm"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default PaymentCollectionTab
