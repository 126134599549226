import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    EditProps,
    FormTab,
    NumberInput,
    Pagination,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    BooleanInput,
    minLength,
    email,
    AutocompleteInput,
    TopToolbar,
    ListButton,
    useNotify,
    useRefresh,
    useRedirect,
    Toolbar,
    SaveButton,
    DeleteButton,
    NumberField,
    RadioButtonGroupInput,
    FormDataConsumer,
    DateInput
} from 'react-admin';
import { InputAdornment, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import isObject from 'lodash/isObject';
import find from 'lodash/find';
import data from '../../data.json';
import { useFormState } from 'react-final-form';

const AccountTitle = ({ record }) => {
    return record ? <span>Edit Account #{record.originId}</span> : null;
}


const CustomActions = (props) => {
    const { basePath } = props;
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
        </TopToolbar>
    )
};


const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar  = (props) => {
     
    const { 
        basePath,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({data}) => {
        notify(`Successfully updated`, 'info', {}, true);
        redirect(false);
        //refresh();
    };

    const transform = data => {
        if(!data.isInternational) {
            let address = data.street ? data.street + ', '  : '';
            address += data.barangay ? data.barangay + ', ' : '';
            address += data.city ? data.city + ', ' : '';
            address += data.province ? data.province : '';
      
            data.accountAddress = address;
        }

        return {
            accountType: data.accountType,
            registrationDate: data.registrationDate,
            accountName: data.accountName,
            accountEmail: data.accountEmail,
            accountMobile: data.accountMobile,
            accountAddress: data.accountAddress,
            leader: isObject(data.leader) ? data.leader['@id'] : data.leader,
            offering: isObject(data.offering) ? data.offering['@id'] : data.offering,
            runner: isObject(data.runner) ? data.runner['@id'] : data.runner,
            remarks: data.remarks,
            status: data.status,
            isActive: data.isActive,
            isMore: data.isMore,
            offeringAmount: parseFloat(data.offeringAmount),
            offeringType: data.offeringType,
            giveOption: data.giveOption,
            modeCollection: data.modeCollection,
            dayCollection: data.dayCollection,
            dateCollection: data.dateCollection,
            dateDeposit: data.dateDeposit,
            isInternational: data.isInternational,
            street: data.street,
            barangay: data.barangay,
            city: data.city,
            province: data.province
        };
    };

    return (
        <Toolbar {...props}  classes={useToolbarStyles()}>
            
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}
                transform={transform}/>
            <DeleteButton />
            
        </Toolbar>
    );

};

const SectionTitle = ({ label }) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

const requiredValidate = [required()];

const useStyles = makeStyles({
    accountType: { display: 'inline-block', width: '19em' },
    registrationDate: { display: 'inline-block', marginLeft: 32, width: '19em' },
    accountEmail: { display: 'inline-block', width: '19em' },
    accountMobile: { display: 'inline-block', marginLeft: 32, width: '19em' },
    widthFormGroup: { display: 'inline-block', width: '19em' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32, width: '19em' },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});


function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }

const toChoices = items => items.map(item => ({ id: item, name: item }));
const CityInput = props => {
    const { values } = useFormState();
    const province = find(data, { provinceName: values.province });
    return (
        <SelectInput
            choices={province ? toChoices(province.cities) : []}
            {...props}
        />
    );
};

const AccountEdit = (props) => {
    const classes = useStyles();
    const [every, setEvery] = useState([]);
    const [province, setProvince] = useState([]);
    const [municipality, setMunicipality] = useState([]);

    useEffect(() => {
        const every = [...Array(31)].map((data, index) => {
            const label = `Every ` + ordinal_suffix_of(index + 1) + ` day of the month`;

            return { id: label, name: label};
        });
        setEvery(every);

        const province = data.map((d, index) => {
            return { id: d.provinceName, name: d.provinceName};
        });
        setProvince(province);
    },[]);

    return (
        <Edit {...props} title={<AccountTitle />} actions={<CustomActions />}>
             <TabbedForm toolbar={<CustomToolbar />}>
                <FormTab
                    label="General Info"
                    contentClassName={classes.tab}
                >
                    <SectionTitle label="Identity" />
                    <SelectInput 
                        source="accountType"
                        fullWidth 
                        resettable={true} 
                        choices={[
                            { id: 'Individual', name: 'Individual' },
                            { id: 'Group', name: 'Group' },
                        ]} 
                        validate={requiredValidate} 
                        formClassName={classes.accountType} 
                    />
                    <DateInput fullWidth defaultValue={new Date()} source="registrationDate" label="Registration Date" validate={[required()]} 
                            formClassName={classes.registrationDate}/>
                    <TextInput 
                        source="accountName" 
                        label="Account Name" 
                        fullWidth
                        validate={requiredValidate} 
                    />
                    <TextInput 
                        source="accountEmail" 
                        fullWidth
                        formClassName={classes.accountEmail} 
                        label="Email" 
                        validate={[required(), email()]}
                    />
                    <TextInput 
                        source="accountMobile" 
                        fullWidth
                        formClassName={classes.accountMobile} 
                        label="Mobile No." 
                        validate={requiredValidate} 
                    />
                    <Separator/>
                    <SectionTitle label="Address" />
                    <BooleanInput fullWidth label="International?" source="isInternational" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if(formData.isInternational) {
                                formData.street = null;
                                formData.barangay = null;
                                formData.province = null;
                                formData.city = null;
                            }
                            return (
                                formData.isInternational && (
                                    <TextInput 
                                        source="accountAddress" 
                                        multiline 
                                        label="Complete Address" 
                                        fullWidth 
                                        validate={[required(), minLength(3)]} 
                                        resettable rows={5} 
                                        rowsMax={15} 
                                    />
                                )
                            )
                        }}
                    </FormDataConsumer>
                    
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if(!formData.isInternational) {
                                formData.accountAddress = null;
                            }
                            return (
                                !formData.isInternational && (
                                    <>
                                    <TextInput 
                                        source="street" 
                                        multiline 
                                        label="Unit / House No, Building / Street Name" 
                                        fullWidth 
                                    />
                                    <TextInput 
                                        source="barangay" 
                                        multiline 
                                        label="Village / Barangay" 
                                        fullWidth 
                                    />
                                    <SelectInput 
                                        source="province"
                                        fullWidth 
                                        choices={province} 
                                        validate={requiredValidate}
                                        className={classes.widthFormGroup}
                                    />
                                    <CityInput  
                                        source="city"
                                        label="City"
                                        fullWidth 
                                        validate={requiredValidate}
                                        className={classes.heightFormGroup}
                                    />
                                    </>
                                )
                            )
                        }}
                    </FormDataConsumer>

                    <TextInput 
                        source="remarks" 
                        multiline 
                        label="Remarks" 
                        fullWidth 
                        validate={[minLength(3)]} 
                        resettable rows={5} 
                        rowsMax={15} 
                    />
                    <SelectInput source="status" resettable={true} choices={[
                        { id: 'Confirmed', name: 'Confirmed' },
                        { id: 'Unconfirmed', name: 'Unconfirmed' },
                        { id: 'Rejected', name: 'Rejected' },
                    ]} validate={[required()]} />
                    <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
                </FormTab>
                <FormTab
                    label="Offering"
                    path="offering"
                    contentClassName={classes.tab}
                >
                    <SelectInput 
                        source="offeringType"
                        label="Type of Offering"
                        fullWidth 
                        choices={[
                            { id: 'PG', name: 'Partial Giving' },
                            { id: 'OTG', name: 'One-Time Giving' }
                        ]} 
                        formClassName={classes.widthFormGroup}
                        validate={requiredValidate}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                           
                            return (
                                formData.offeringType === 'PG' && (
                                    <>
                                        <BooleanInput fullWidth label="Is more than P30,000.00 per month or not in the list?" source="isMore" />
                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => {
                                                if(!formData.isMore) {
                                                    formData.offeringAmount = null;
                                                }
                                                return (
                                                    !formData.isMore && (
                                                        <ReferenceInput
                                                            fullWidth
                                                            formClassName={classes.offering} 
                                                            allowEmpty
                                                            filter={{isActive: true}} 
                                                            source="offering" reference="offerings"
                                                            filterToQuery={searchText => ({ offeringName: searchText })}
                                                            format={v => v ? v['@id'] || v : ''}
                                                            resettable
                                                            validate={requiredValidate} 
                                                        >
                                                            <AutocompleteInput
                                                                optionText="offeringName"
                                                            />
                                                        </ReferenceInput>
                                                    )
                                                )
                                            }}
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => {
                                                if(formData.isMore) {
                                                    formData.offering = null;
                                                }
                                                return (
                                                    formData.isMore && (
                                                        <TextInput 
                                                            type="number"
                                                            source="offeringAmount" 
                                                            fullWidth
                                                            label="If more than P30,000.00 per month or not in the list (Specify the amount)"
                                                            validate={requiredValidate} 
                                                        />
                                                    )
                                                )
                                            }}
                                        </FormDataConsumer>
                                        

                                        <RadioButtonGroupInput validate={requiredValidate} source="giveOption" label="How to give pledges?" choices={[
                                            { id: 'Collection', name: 'Collection' },
                                            { id: 'Deposit', name: 'Deposit' },
                                        ]} />

                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => {
                                                if(formData.giveOption === 'Collection') {
                                                    formData.dateDeposit = null;
                                                }

                                                if(formData.modeCollection === 'Every 15th/ 30th') {
                                                    formData.dateCollection = null;
                                                    formData.dayCollection = null;
                                                }

                                                return (
                                                    formData.giveOption === 'Collection' && (
                                                        <SelectInput 
                                                            source="modeCollection"
                                                            label="Mode of Collection"
                                                            fullWidth 
                                                            choices={[
                                                                { id: 'Weekly', name: 'Weekly' },
                                                                { id: 'Every 15th/ 30th', name: 'Every 15th/ 30th' },
                                                                { id: 'Monthly', name: 'Monthly' },
                                                            ]} 
                                                            formClassName={classes.widthFormGroup}
                                                            validate={requiredValidate}
                                                        />
                                                    )
                                                )
                                            }}
                                        </FormDataConsumer>

                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => {
                                                if(formData.modeCollection === 'Weekly') {
                                                    formData.dateCollection = null;
                                                }
                                                return (
                                                    formData.giveOption === 'Collection' && formData.modeCollection === 'Weekly' && (
                                                        <SelectInput 
                                                            source="dayCollection"
                                                            label="Specific day for collection"
                                                            fullWidth 
                                                            choices={[
                                                                { id: 'Sunday', name: 'Sunday' },
                                                                { id: 'Monday', name: 'Monday' },
                                                                { id: 'Tuesday', name: 'Tuesday' },
                                                                { id: 'Wednesday', name: 'Wednesday' },
                                                                { id: 'Thursday', name: 'Thursday' },
                                                                { id: 'Friday', name: 'Friday' },
                                                                { id: 'Saturday', name: 'Saturday' },
                                                            ]} 
                                                            formClassName={classes.heightFormGroup} 
                                                            validate={requiredValidate}
                                                        />
                                                    )
                                                )
                                            }}
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => {
                                                if(formData.modeCollection === 'Monthly') {
                                                    formData.dayCollection = null;
                                                }
                                                return (
                                                    formData.giveOption === 'Collection' && formData.modeCollection === 'Monthly' && (
                                                        <SelectInput 
                                                            source="dateCollection"
                                                            label="Specific date for collection"
                                                            fullWidth 
                                                            choices={every} 
                                                            formClassName={classes.heightFormGroup}
                                                            validate={requiredValidate}
                                                        />
                                                    )
                                                )
                                            }}
                                        </FormDataConsumer>

                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => {
                                                if(formData.giveOption === 'Deposit') {
                                                    formData.modeCollection = null;
                                                    formData.dateCollection = null;
                                                    formData.dayCollection = null;
                                                }
                                                return (
                                                    formData.giveOption === 'Deposit' && (
                                                        <SelectInput 
                                                            source="dateDeposit"
                                                            label="Specific Date to Deposit"
                                                            fullWidth 
                                                            choices={every} 
                                                            formClassName={classes.widthFormGroup}
                                                            validate={requiredValidate}
                                                        />
                                                    )
                                                )
                                            }}
                                        </FormDataConsumer>
                                        
                                        <Separator/>
                                        
                                    </>
                                )
                            )
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if(formData.offeringType === 'OTG') {
                                formData.offering = null;
                                formData.isMore = null;
                                formData.giveOption = null;
                                formData.modeCollection = null;
                                formData.dateCollection = null;
                                formData.dayCollection = null;
                            }
                            if(formData.offeringType === 'PG') {
                                formData.giveOption = 'Collection';
                            }
                            return (
                                formData.offeringType === 'OTG' && (
                                    <TextInput 
                                        type="number"
                                        source="offeringAmount" 
                                        fullWidth
                                        label="Offering Amount"
                                        validate={requiredValidate} 
                                        formClassName={classes.widthFormGroup}
                                    />
                                )
                            )
                        }}
                    </FormDataConsumer>
                    <ReferenceInput
                        fullWidth
                        label="Network Leader"
                        allowEmpty
                        filter={{isActive: true}} 
                        source="leader" reference="leaders"
                        filterToQuery={searchText => ({ leaderName: searchText })}
                        format={v => v ? v['@id'] || v : ''}
                    >
                        <AutocompleteInput
                            optionText="leaderName"
                        />
                    </ReferenceInput>
                    
                </FormTab>
                
                {/* <FormTab
                    label="Assign Runner"
                    path="runner"
                    contentClassName={classes.tab}
                >
                    <ReferenceInput
                        fullWidth
                        allowEmpty
                        filter={{isActive: true}} 
                        source="runner" reference="runners"
                        filterToQuery={searchText => ({ runnerName: searchText })}
                        format={v => v ? v['@id'] || v : ''}
                    >
                        <AutocompleteInput
                            optionText="runnerName"
                        />
                    </ReferenceInput>
                </FormTab> */}
            </TabbedForm>
        </Edit>
    )
}


export default AccountEdit
