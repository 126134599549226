import {cloneElement} from 'react';
import {
    SimpleForm,
    TextInput,
    required,
    maxLength,
    Toolbar,
    SaveButton,
    useCreateController,
    CreateContextProvider 
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const CustomCreateToolbar  = (props) => {
    
    const { 
        basePath,
        handleSubmitWithRedirect,
        invalid,
        saving,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({data}) => {
        notify(`Successfully Added`, 'info');
        redirect('list', basePath);
        refresh();
    };

    return (
        <Toolbar {...props}>
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}/>
        </Toolbar>
    );

};

export const styles = {
    root: {
        paddingTop: 0,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0em 1em 0em 1em',
        borderBottom: '1px solid #e0e0e3'
    },
};

const useStyles = makeStyles(styles);

const CustomCreate = props => {
    const createControllerProps = useCreateController(props);
    const {
        basePath, // deduced from the location, useful for action buttonss
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = createControllerProps;
    return (
        <CreateContextProvider value={createControllerProps}>
            {cloneElement(props.children, {
                basePath,
                record,
                redirect,
                resource,
                save,
                saving,
                version,
            })}
        </CreateContextProvider>
    );
}

const CountryCreate = ({ onCancel, ...props}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    Add Country
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <CustomCreate {...props}>
                <SimpleForm
                    toolbar={<CustomCreateToolbar />}>
                        <TextInput autoFocus source="countryCode" label="Code" validate={[required(), maxLength(2, 'Should be at most 2 characters')]}/>
                        <TextInput source="countryName" label="Country Name" validate={required()}/>
                </SimpleForm>
            </CustomCreate>
        </div>
    )
};

export default CountryCreate;