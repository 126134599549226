import { Fragment, useCallback } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteWithConfirmButton,
    TextInput,
    BulkDeleteWithConfirmButton,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    SearchInput
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { Drawer } from '@material-ui/core';
import { Route, useHistory } from 'react-router-dom';

import CountryEdit from './CountryEdit';
import CountryCreate from './CountryCreate';
import BulkDeleteButton from './BulkDeleteButton';

const CountryFilter = props => (
    <Filter {...props}>
        <SearchInput placeholder="Search Country Name" source="countryName" alwaysOn/>
    </Filter>
);

const ListBulkActionButtons = (props) => {
    return (
        <Fragment>
            <BulkDeleteButton {...props}></BulkDeleteButton>
        </Fragment>
    )
};

const ListActions = ({basePath}) => {
    return (
        <TopToolbar>
            <CreateButton basePath={basePath} />
            <RefreshButton />
        </TopToolbar>
    );
};

export const styles = {
    drawerContent: {
        width: 300
    },
    countryCode: { width: '10%', textAlign: 'center' },
    countryName: { width: '70%' },
};

const useStyles = makeStyles(styles);

const CountryList = props => {
    const { 
        basePath
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const handleClose = useCallback(() => {
        history.push('/countries');
    }, [history]);

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info');
        redirect('list', basePath);
        refresh();
    }

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Countries"
                bulkActionButtons={<ListBulkActionButtons />}
                actions={<ListActions/>}
                filters={<CountryFilter />}
                perPage={25}
                sort={{ field: 'id', order: 'DESC' }}>
                <Datagrid>
                    <TextField source="countryCode" label="Code" cellClassName={classes.countryCode} headerClassName={classes.countryCode}/>
                    <TextField source="countryName" label="Country Name" cellClassName={classes.countryName}/>
                    <EditButton />
                    <DeleteWithConfirmButton onSuccess={onSuccessDelete} confirmTitle ="Confirm Delete"/>
                </Datagrid>
            </List>
            <Route path="/countries/create">
                {({ match }) => (
                    <Drawer
                        ModalProps={{ disableBackdropClick: true, disableEscapeKeyDown: true }}
                        open={!!match}
                        anchor="right"
                        onClose={handleClose}
                    >
                        <CountryCreate
                            className={classes.drawerContent}
                            onCancel={handleClose}
                            {...props}
                        />
                    </Drawer>
                )}
            </Route>
            <Route path="/countries/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        );

                        return (
                            <Drawer
                                ModalProps={{ disableBackdropClick: true, disableEscapeKeyDown: true }}
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                            >
                                {isMatch ? (
                                    <CountryEdit
                                        className={classes.drawerContent}
                                        id={isMatch ? decodeURIComponent(match.params.id) : null}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : (
                                    <div className={classes.drawerContent} />
                                )}
                            </Drawer>
                        );
                    }}
                </Route>
        </Fragment>

    )
};

export default CountryList;