import * as React from 'react';
import { Route } from 'react-router-dom';
import ChangePassword from './changePassword/ChangePassword';
import Configuration from './configuration/Configuration';
import SendSMS from './pages/sms-email/send-sms/SendSMS';
import SendEmail from './pages/sms-email/send-email/SendEmail';
import Report from './pages/reports/Report';
import DepositPostingList from './pages/deposit-posting/DepositPostingList';
import PaymentCollections from './pages/inquiries/payment-collections/PaymentCollectionList';

const CustomRoute = [
    <Route exact path="/change-password" render={() => <ChangePassword />} />,
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/send-sms" render={() => <SendSMS />} />,
    <Route exact path="/send-email" render={() => <SendEmail />} />,
    <Route exact path="/reports" render={() => <Report />} />,
    <Route exact path="/deposit-posting" render={(routeProps) => {
        return (
            <DepositPostingList />
        );
    }} />,
    <Route exact path="/inquiries/payment-collections" render={(routeProps) => {
        return (
            <PaymentCollections 
                hasShow={false}
                hasList={true} 
                hasCreate={false} 
                hasEdit={false}
                syncWithLocation={true}
                resource="payment-collections" basePath={routeProps.match.url}/>
        );
    }} />,
    // <Route exact path="/inquiries/payment-collections/:id/show" render={(routeProps) => {
    //     return (
    //         <PaymentCollections 
    //             hasShow={false}
    //             hasList={false} 
    //             hasCreate={false} 
    //             hasEdit={false}
    //             id={decodeURIComponent((routeProps.match).params.id)}
    //             resource="payment-collections" basePath={routeProps.match.url}/>
    //     );
    // }} />,
];

export default CustomRoute;