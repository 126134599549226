import {
    AppBar as MuiAppBar,
    Divider,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import MenuIcon from '@material-ui/icons/Menu';
  import classNames from 'classnames';
  import PropTypes from 'prop-types';
  import * as React from 'react';
  import { Children, cloneElement } from 'react';
  import { LoadingIndicator, toggleSidebar, useTranslate } from 'react-admin';
  import { useDispatch } from 'react-redux';
  import Logo from './Logo';

  const useStyles = makeStyles(
    theme => ({
      divider: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: 1,
      },
      toolbar: {
        paddingRight: 24,
      },
      menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
      },
      menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
      },
      menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
      },
      title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    }),
    { name: 'RaAppBar' }
);

const CustomAppBar = props => {
    const {
      children,
      classes: classesOverride,
      className,
      color = 'secondary',
      logout,
      open,
      title,
      userMenu,
      ...rest
    } = props;
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const translate = useTranslate();
  
    return (
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar disableGutters variant={isXSmall ? 'regular' : 'dense'} className={classes.toolbar}>
          <Tooltip
            title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
              _: 'Open/Close menu',
            })}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          <Logo/>
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          {/* {!isXSmall && <SearchBar />} */}
          {/* {!isXSmall && (
            <Divider className={classes.divider} flexItem orientation="vertical" variant="middle" />
          )} */}
          {/* <AssistantIcon />
          <NotificationIcon /> */}
          {cloneElement(userMenu, { logout })}
          <LoadingIndicator />
        </Toolbar>
      </MuiAppBar>
    );
};

export default CustomAppBar;