import React from 'react';
import { Fragment, cloneElement } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput,
    SelectInput,
    NumberField
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment  } from '@material-ui/core';


import BulkPostButton from './BulkPostButton';

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const AccountFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
        <TextInput
            alwaysOn
            resettable
            helperText={false}
            source="depositNo"
            label="Search Deposit Slip No."
            InputProps={{
                endAdornment: (
                <InputAdornment>
                    <SearchIcon color="disabled" />
                </InputAdornment>
                )
            }}
        />
        <SelectInput
            source="status"
            choices={[
                { id: 'Active', name: 'Active' },
                { id: 'Posted', name: 'Posted' },
                { id: 'Cancelled', name: 'Cancelled' },
            ]}
            className={classes.status}
        />
    </Filter>
    )
};

const ListBulkActionButtons = (props) => {
    return (
        <Fragment>
            <BulkPostButton {...props} />
        </Fragment>
    )
};

const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} label="Add Collection"/>
            <RefreshButton />
        </TopToolbar>
    );
};

export const styles = {
    depositNo: { width: '20%' },    
    depositDate: { width: '20%', textAlign: 'center'},
    remarks: { width: '15%' },
    status: { width: '10%', textAlign: 'center' },
    // paymentDate: { width: '10%', textAlign: 'center' },
    createdDate: { width: '10%', textAlign: 'center' },
    amount: { width: '15%'},
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    },
};

const useStyles = makeStyles(styles);

const PaymentList = (props) => {

    const classes = useStyles();

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Collections"
                bulkActionButtons={<ListBulkActionButtons />}
                actions={<ListActions/>}
                filters={<AccountFilter />}
                perPage={25}
                sort={{ field: 'createdAt', order: 'DESC' }}>
                <Datagrid 
                    optimized
                    isRowSelectable={ record => record.status !== 'Posted' }
                    >
                    {/* <DateField source="paymentDate" label="Payment Date" cellClassName={classes.paymentDate}/> */}
                    <TextField source="depositNo" label="Deposit Slip No." cellClassName={classes.depositNo}/>
                    <DateField source="depositDate" label="Deposit Date" cellClassName={classes.depositDate}/>
                    <NumberField source="totalAmount" label="Total Amount" cellClassName={classes.amount}/>
                    <TextField source="remarks" label="Remarks" cellClassName={classes.remarks}/>
                    <TextField source="status" label="Status" cellClassName={classes.status} headerClassName={classes.status}/>
                    <DateField source="createdAt" showTime={true} label="CreatedAt" cellClassName={classes.createdAt} headerClassName={classes.createdAt}/>
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default PaymentList
