import React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
import { format, subDays, addDays } from 'date-fns';

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date) =>
    new Date(date).toLocaleDateString();

const aggregateCollectonsByDay = (collections)=>
        collections
        .filter((collection) => collection.status !== 'Cancelled' )
        .reduce((acc, curr) => {
            if(curr.status === 'Posted' && curr.isPosted) {
                const day = format(curr.collectedDate, 'YYYY-MM-DD');
                if (!acc[day]) {
                    acc[day] = 0;
                }
                acc[day] += curr.paidAmount;
            }
            return acc;
        }, {});

const getRevenuePerDay = (collections) => {
    const daysWithRevenue = aggregateCollectonsByDay(collections);
    return lastMonthDays.map(date => ({
        date: date.getTime(),
        total: daysWithRevenue[format(date, 'YYYY-MM-DD')] || 0,
    }));
};


// const data = [];

// const random = () => {
//     const min = 1;
//     const max = 10000;
//     const rand = min + Math.random() * (max - min);

//     return rand;
// }
// const getRevenuePerDay = (orders) => {
//     return lastMonthDays.map(date => ({
//         date: date.getTime(),
//         total: random(),
//     }));
// };

const DailyCollectionChart = ({paymentCollections}) => {
    return (
        <Card>
            <CardHeader title={'30 Day Collection History'} />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart data={getRevenuePerDay(paymentCollections)}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                name="Date"
                                type="number"
                                scale="time"
                                domain={[
                                    addDays(aMonthAgo, 1).getTime(),
                                    new Date().getTime(),
                                ]}
                                tickFormatter={dateFormatter}
                            />
                            <YAxis dataKey="total" name="Collection" />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                                cursor={{ strokeDasharray: '3 3' }}
                                formatter={value =>
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'PHP',
                                    }).format(value)
                                }
                                labelFormatter={(label) =>
                                    dateFormatter(label)
                                }
                            />
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}

export default DailyCollectionChart
