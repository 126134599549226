import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    Toolbar,
    SaveButton,
    useEditController,
    minLength,
    EditContextProvider,
    DateInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    AutocompleteInput,
} from 'react-admin'
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import isObject from 'lodash/isObject';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FromDialog from './FormDialog';
import PaymentCollectionTab from './PaymentCollectionTab';

const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
        notify(`Successfully Updated`, 'info', {}, true);
        redirect(false);
        refresh();
     };

     const transform = data => {

        return {
            type: data.type,
            bankAccount: isObject(data.bankAccount) ? data.bankAccount['@id'] : data.bankAccount,
            runner: isObject(data.runner) ? data.runner['@id'] : data.runner,
            depositNo: data.depositNo,
            depositedDate: data.depositedDate,
            remarks: data.remarks,
            depositedAmount: data.depositedAmount,
            status: 'Active',
        }
    };

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}
                 transform={transform}/>
         </Toolbar>
     );
 
};

const BankAccountField = ({record}) => {
    return (
        <>
            <span>({record.bankCode}) {record.accountNo} - {record.accountName}</span>
        </>
    )
};

const useStyles = makeStyles({
    type: { display: 'inline-block', width: '49%' },
    runner: { display: 'inline-block', marginLeft: '2%', width: '49%' },
    depositDate: { display: 'inline-block',  width: '49%' },
    depositNo: { display: 'inline-block', marginLeft: '2%', width: '49%' },
    depositedAmount: { width: '49%' },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div> {children}</div>
        )}
      </div>
    );
  }



const DepositEdit = ({ onCancel, ...props}) => {
    const classes = useStyles();
    const controllerProps  = useEditController(props);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <FromDialog onCancel={onCancel} title="Edit Deposit Slip" >
            <Paper square>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label="Deposit Slip Info" />
                <Tab label="Payment Collection Info" />
            </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                <EditContextProvider value={controllerProps}>
                    <SimpleForm 
                        basePath={controllerProps.basePath}
                        record={controllerProps.record}
                        save={controllerProps.save}
                        saving={controllerProps.saving}
                        version={controllerProps.version}
                        redirect={controllerProps.redirect}
                        resource={controllerProps.resource}
                        toolbar={<CustomToolbar />}
                    >
                        <SelectInput 
                            fullWidth
                            label="Type"
                            source="type" 
                            resettable={true}
                            defaultValue="CSH"
                            choices={[
                                { id: 'CSH', name: 'Cash' },
                                { id: 'CHK', name: 'Check' },
                                { id: 'BNT', name: 'Bank Transfer' }
                        ]} validate={[required()]} formClassName={classes.type}/>
                        <ReferenceInput 
                            fullWidth
                            filter={{isActive: true, pagination: false}}
                            sort={{ field: 'createdAt', order: 'ASC' }} label="Select Runner" source="runner" reference="runners" validate={[required()]}
                            formClassName={classes.runner} 
                            format={v => v ? v['@id'] || v : ''}>
                            <SelectInput optionText="runnerName" />
                        </ReferenceInput>
                        <DateInput fullWidth defaultValue={new Date()} source="depositedDate" label="Date Deposited" validate={[required()]} 
                            formClassName={classes.depositDate}/>
                        <TextInput fullWidth source="depositNo" label="Deposit Slip No"
                            formClassName={classes.depositNo}/>
                        <ReferenceInput required 
                            fullWidth
                            filter={{isActive: true, pagination: false}}
                            sort={{ field: 'createdAt', order: 'ASC' }}
                            abel="Select Bank Account" source="bankAccount" 
                            reference="bank-accounts" validate={[required()]}
                            format={v => v ? v['@id'] || v : ''}>
                            <SelectInput optionText={<BankAccountField />} />
                        </ReferenceInput>
                        <NumberInput fullWidth source="depositedAmount" label="Deposited Amount" validate={[required()]}
                        formClassName={classes.depositedAmount}/>
                        <TextInput 
                            fullWidth
                            source="remarks" 
                            rowsMax={15}
                            rows={5} 
                            multiline 
                            label="Remarks" 
                            validate={minLength(3)}
                        />
                    </SimpleForm>
                </EditContextProvider>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PaymentCollectionTab record={controllerProps.record}/>
            </TabPanel>
            {/* <EditContextProvider value={controllerProps}>
                <SimpleForm 
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomToolbar />}
                >
                    <SelectInput 
                        fullWidth
                        label="Type"
                        source="type" 
                        resettable={true}
                        defaultValue="CSH"
                        choices={[
                        { id: 'CSH', name: 'Cash' },
                        { id: 'CHK', name: 'Check' }
                    ]} validate={[required()]} formClassName={classes.type}/>
                    <DateInput fullWidth defaultValue={new Date()} source="depositedDate" label="Date Deposited" validate={[required()]} 
                        formClassName={classes.depositDate}/>
                    <TextInput fullWidth source="depositNo" label="Deposit Slip No" validate={[required()]}
                        formClassName={classes.depositNo}/>
                    <ReferenceInput required 
                        fullWidth
                        filter={{isActive: true, pagination: false}}
                        sort={{ field: 'createdAt', order: 'ASC' }}
                        abel="Select Bank Account" source="bankAccount" 
                        reference="bank-accounts" validate={[required()]}
                        format={v => v ? v['@id'] || v : ''}>
                        <SelectInput optionText={<BankAccountField />} />
                    </ReferenceInput>
                    <TextInput 
                        fullWidth
                        source="remarks" 
                        rowsMax={15}
                        rows={5} 
                        multiline 
                        label="Remarks" 
                        validate={minLength(3)}
                    />
                </SimpleForm>
            </EditContextProvider> */}
        </FromDialog>
    )
}

export default DepositEdit
