import yellow from '@material-ui/core/colors/yellow';
import { useTheme } from '@material-ui/core/styles';


const rowStyle = (selectedRow) => (record) => {
    const theme = useTheme();
    let style = {};
    if (!record) {
        return style;
    }
    if (selectedRow && selectedRow === record.id) {
        style = {
            ...style,
            backgroundColor: theme.palette.action.selected,
        };
    }

    if (record.depositedAmount !== record.totalAmount)
        return {
            ...style,
            backgroundColor: yellow[500],
        };

    return style;
};

export default rowStyle;