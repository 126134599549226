import React, { useState, useCallback } from 'react'
import {
    Button
} from 'react-admin'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PaymentCollectionShow from './PaymentCollectionShow';

const ShowButton = ({ record, ...props}) => {

    const [showPreviewDialog, setShowPreviewDialog] = useState(false);

        
    const handleShowClick = useCallback(
        event => {
            event.preventDefault();
            setShowPreviewDialog(true);
        },
        [setShowPreviewDialog]
    );

    const handleCloseShow = useCallback(() => {
        setShowPreviewDialog(false);
    }, [setShowPreviewDialog]);

    return (
        <>
            <Button
                color="primary"
                label="Show"
                onClick={handleShowClick}
            >
                <VisibilityIcon />
            </Button>

            <Dialog
                fullWidth
                open={showPreviewDialog}
                maxWidth={'sm'}
                onClose={handleCloseShow}
                scroll={'body'}
                disableBackdropClick={true}
            >
                <DialogContent style={{padding: '0px'}}>
                    {record ? (
                        <PaymentCollectionShow
                            id={record ? decodeURIComponent(record.id) : null}
                            onCancel={handleCloseShow}
                            {...props}
                        />
                    ) : (
                        null
                    )}
                </DialogContent>
            
            </Dialog>
        </>
    )
}

export default ShowButton
