import React from 'react'
import { 
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    Toolbar,
    SaveButton,
    ListButton,
    TopToolbar,
    ReferenceInput,
    SelectInput,
    email,
    DateInput
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import ChevronLeft from '@material-ui/icons/ChevronLeft';
 import { makeStyles } from '@material-ui/core/styles';
 
 const CustomActions = (props) => {
     const { basePath } = props;
     return (
         <TopToolbar>
             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
         </TopToolbar>
     )
 };
 
 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Added`, 'info');
         redirect('edit', basePath, data.id, data);
         //refresh();
     };

     const transform = data => ({
        ...data,
        status: 'Unconfirmed',
        isActive: true,
        channel: 'App'
    });

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}
                 transform={transform}/>
         </Toolbar>
     );
 
 };

 
 export const styles = {
    accountType: { display: 'inline-block', width: '19em' },
    registrationDate: { display: 'inline-block', marginLeft: 32, width: '19em' },
    email: { display: 'inline-block' },
    mobileNo: { display: 'inline-block', marginLeft: 32 },
    name: { width: '40em' },
    remarks: { maxWidth: '40em' },
};

const useStyles = makeStyles(styles);

const AccountCreate = (props) => {

    const classes = useStyles(props);

    return (
        <Create title="Add Account" {...props} actions={<CustomActions />}>
            <SimpleForm 
                toolbar={<CustomToolbar />}>
                {/* <ReferenceInput autoFocus required filter={{isActive: true, pagination: false}} label="Group" source="groupId" reference="groups" validate={[required()]}>
                    <SelectInput optionText="groupName" />
                </ReferenceInput> */}
                {/* <ReferenceInput 
                    sort={{ field: 'countryName', order: 'ASC' }}
                    label="Country" source="countryCode" reference="countries" 
                    validate={[required()]}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput> */}
                <SelectInput 
                        source="accountType"
                        fullWidth 
                        resettable={true} 
                        choices={[
                            { id: 'Individual', name: 'Individual' },
                            { id: 'Group', name: 'Group' },
                        ]} 
                        validate={[required()]} 
                        formClassName={classes.accountType} 
                    />
                <DateInput fullWidth defaultValue={new Date()} source="registrationDate" label="Registration Date" validate={[required()]} 
                            formClassName={classes.registrationDate}/>
                <TextInput source="accountName" formClassName={classes.name} label="Account Name" fullWidth validate={[required()]} />
                {/* <TextInput source="accountEmail" formClassName={classes.email} label="Email" validate={[required(), email()]}/>
                <TextInput source="accountMobile" formClassName={classes.mobileNo} label="Mobile No." validate={[required()]}/>
                <TextInput source="accountAddress" formClassName={classes.address} multiline label="Complete Address" fullWidth validate={[required(),minLength(3)]} resettable rows={5} rowsMax={15} /> */}
                <TextInput source="remarks" formClassName={classes.remarks} multiline label="Remarks" fullWidth validate={[minLength(3)]} resettable rows={5} rowsMax={15} />
                {/* <SelectInput source="status" resettable={true} choices={[
                    { id: 'Confirmed', name: 'Confirmed' },
                    { id: 'Unconfirmed', name: 'Unconfirmed' },
                    { id: 'Rejected', name: 'Rejected' },
                ]} validate={[required()]}/>
                <BooleanInput source="isActive" label="IsActive" defaultValue={true}/> */}
            </SimpleForm>
        </Create>
    )
}

export default AccountCreate
