import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    Toolbar,
    SaveButton,
    useCreateController,
    minLength,
    CreateContextProvider,
    DateInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    AutocompleteInput,
    FormDataConsumer
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import { withStyles } from '@material-ui/core';
 import { makeStyles } from '@material-ui/core/styles';

 import FromDialog from './FormDialog';

 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Added`, 'info');
         redirect('list', basePath);
         refresh();
     };

     const transform = data => {

        return {
            ...data,
            status: 'Active',
        }
    };

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}
                 transform={transform}/>
         </Toolbar>
     );
 
};

const AutocompleteInputInDialog = withStyles({
    suggestionsContainer: { zIndex: 2000 },
})(AutocompleteInput);

const useStyles = makeStyles({
    
    leader: { display: 'inline-block', width: '49%' },
    runner: { display: 'inline-block', marginLeft: '2%', width: '49%' },
    collectedDate: { display: 'inline-block',  width: '49%' },
    type: { display: 'inline-block', marginLeft: '2%', width: '49%' },
    referenceNo: { display: 'inline-block', width: '49%' },
    paidAmount: { display: 'inline-block', marginLeft: '2%', width: '49%' },
});


const PaymentCollectionCreate = ({ onCancel, ...props}) => {
    const classes = useStyles();
    const controllerProps  = useCreateController(props);

    return (
        <FromDialog onCancel={onCancel} title="Add Payment Collection" >
            <CreateContextProvider value={controllerProps}>
                <SimpleForm 
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomToolbar />}
                >
                    <ReferenceInput 
                        fullWidth
                        filter={{isActive: true, pagination: false}}
                        sort={{ field: 'createdAt', order: 'ASC' }} label="Select Network Leader" source="leader" reference="leaders" validate={[required()]} 
                        formClassName={classes.leader} >
                        <SelectInput optionText="leaderName" />
                    </ReferenceInput>
                    <ReferenceInput 
                        fullWidth
                        filter={{isActive: true, pagination: false}}
                        sort={{ field: 'createdAt', order: 'ASC' }} label="Select Runner" source="runner" reference="runners" validate={[required()]}
                        formClassName={classes.runner} >
                        <SelectInput optionText="runnerName" />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            formData.leader && (
                            <ReferenceInput
                                fullWidth
                                filter={{isActive: true, status: 'Confirmed', leader: formData.leader}} 
                                label="Account"
                                source="account" reference="accounts"
                                filterToQuery={searchText => ({ accountName: searchText })}
                                sort={{ field: 'id', order: 'ASC' }}
                                validate={[required()]} 
                                format={v => v ? v['@id'] || v : ''}
                                resettable>
                                <AutocompleteInputInDialog optionText="accountName"/>
                            </ReferenceInput>
                            )
                        )}
                    </FormDataConsumer>
                    
                    <DateInput fullWidth defaultValue={new Date()} source="collectedDate" label="Date Collected" validate={[required()]} 
                        formClassName={classes.collectedDate}/>
                    <SelectInput 
                        fullWidth
                        label="Type"
                        source="type" 
                        resettable={true}
                        defaultValue="CSH"
                        choices={[
                        { id: 'CSH', name: 'Cash' },
                        { id: 'CHK', name: 'Check' },
                        { id: 'BNT', name: 'Bank Transfer' },
                        { id: 'GCASH', name: 'GCash' }
                    ]} validate={[required()]} formClassName={classes.type}/>
                    <TextInput fullWidth source="referenceNo" label="Reference No" validate={[required()]}
                        formClassName={classes.referenceNo}/>
                    <NumberInput fullWidth source="paidAmount" label="Paid Amount" validate={[required()]}
                        formClassName={classes.paidAmount}/>
                </SimpleForm>
            </CreateContextProvider>
        </FromDialog>
    )
}

export default PaymentCollectionCreate
