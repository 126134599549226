import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    SaveButton,
    Toolbar,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useEditController,
    EditContextProvider
 } from 'react-admin'
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import FromDialog from './FormDialog';

const CustomEditToolbar  = (props) => {
    
    const { 
        basePath,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({data}) => {
        notify(`Successfully Updated`, 'info', {}, true);
        redirect('list', basePath);
        refresh();
    };

    const transform = data => ({
        bankCode: data.bankCode,
        accountNo: data.accountNo,
        isActive: data.isActive,
        accountName: data.accountName
    });

    return (
        <Toolbar {...props}>
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}
                transform={transform}/>
        </Toolbar>
    );

};

const useStyles = makeStyles({
    bankCode: { width: '50%' },
    accountNo: { width: '50%' },
});

const BankAccountEdit = ({ onCancel, ...props}) => {
    const classes = useStyles();
    
    const controllerProps = useEditController(props);
    return (
        <FromDialog onCancel={onCancel} title="Edit Bank Account">
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomEditToolbar />}>
                    
                    <TextInput fullWidth autoFocus source="bankCode" label="Bank Code" validate={[required()]} formClassName={classes.bankCode}/>
                    <TextInput fullWidth source="accountNo" label="Account No." validate={[required()]} formClassName={classes.accountNo}/>
                    <TextInput fullWidth source="accountName" label="Account Name" validate={[required()]}/>
                    <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
                </SimpleForm>
            </EditContextProvider>
        </FromDialog>
    )
}

export default BankAccountEdit
