import { useQueryWithStore, useDataProvider  } from 'react-admin';
import forEach from 'lodash/forEach';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT || '';
const baseUrl = process.env.REACT_APP_API_BASE_URL || '';

const handleAuthLogin = async (params) =>  {
    const { username, password } = params;

    const request = new Request(entrypoint + '/login_check', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                console.log(response.body);
                throw new Error();
            }
            return response.json();
        })
        .then(auth => {
            localStorage.setItem('token', auth.token);
        });
    
}

const handleAuthLogout = async () => {
    localStorage.removeItem('token');
    return Promise.resolve();
}

const handleAuthError = async (err) =>  {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
        localStorage.removeItem("token");
        return Promise.reject();
      }
    return Promise.resolve();
}

const handleAuthCheck = async () =>  {
    return localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject();
}

const getUserRolePermissions = () => {

    return new Promise((resolve, reject) => {
        const request = new Request(entrypoint + '/users/me/permissions', {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/ld+json' 
            }
        });
    
        fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            const id = data.id;
            const rolePermissions = data.rolePermissions;
            
            resolve({ id, rolePermissions });
        })
        .catch((e) => {
            reject(e);
        });
    });
}

const handleGetPermissions = async () =>  {
    try {
        const permission = await getUserRolePermissions();
        const rolePermissions = permission.rolePermissions;
        let permissions = [];
        let roles = [];
        forEach(rolePermissions, (element) => {
            const a = [...permissions, ...element.permission];
            const b = [...roles, element.role];
            permissions = a;
            roles = b;
        });
        const roleWithPermissions = { roles, permissions};
        return roleWithPermissions;
    } catch (e) {
        console.log('HandleGetPermission: no user is logged in or tokenResult error', {
            e,
        });
        return null;
    }
      
}

const getUserInfo = () => {

    return new Promise((resolve, reject) => {
        const request = new Request(entrypoint + '/users/me', {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/ld+json' 
            }
        });
    
        fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            const id = data.id;
            const fullName = data.accountName;
            const avatar = baseUrl + data.photoUrl;
            const rolePermissions = data.rolePermissions;
            const leaderId = data.leaderId;

            resolve({ id, fullName, avatar, leaderId, rolePermissions });
        })
        .catch((e) => {
            reject(e);
        });
    });
}

const handleGetIdentity = async () => {
    try {
        const { id, fullName, avatar, leaderId, rolePermissions } = await getUserInfo();
        const identity = {
          id,
          fullName,
          avatar,
          leaderId,
          rolePermissions
        };
        return identity;
    } catch (e) {
        console.log('HandleGetIdentity: no user is logged in', {
            e,
        });
        return null;
    }
}

const authProvider = {

    login: (params) => handleAuthLogin(params),
    logout: () => handleAuthLogout(),
    checkError: (err) => handleAuthError(err),
    checkAuth: () => handleAuthCheck(),
    getPermissions: () => handleGetPermissions(),
    getIdentity: () => handleGetIdentity()
}



export default authProvider;