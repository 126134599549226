import React from 'react'
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
    Paper
} from '@material-ui/core';
import Skeleton from "react-loading-skeleton";


const DatagridLoading = ({nbChildren, nbFakeLines}) => {
    
    const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

    return (
        <TableContainer component={Paper}>
            <Table size={'small'}>
            <TableHead>
                <TableRow >
                    {times(nbChildren, key => (
                        <TableCell
                            variant="head"
                            key={key}
                        >
                            <Skeleton />
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {times(nbFakeLines, key1 => (
                    <TableRow key={key1} style={{ opacity: 1 / (key1 + 1) }}>
                        {times(nbChildren, key2 => (
                            <TableCell key={key2}>
                                <Skeleton />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}

DatagridLoading.defaultProps = {
    nbChildren: 5,
    nbFakeLines: 4
};

export default DatagridLoading
