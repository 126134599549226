import React, { cloneElement } from 'react'
import PropTypes from 'prop-types';
 import Paper from '@material-ui/core/Paper';
 import { IconButton, Typography } from '@material-ui/core';
 import CloseIcon from '@material-ui/icons/Close';
 import { makeStyles  } from '@material-ui/core/styles';

 const styles = (theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0.5,2),
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none'
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles(styles);

const FormDialog = ({ onCancel, title, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper square  className={classes.title}>
                <Typography variant="h6">
                    {title}
                </Typography>
                <IconButton onClick={onCancel} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </Paper>
            {cloneElement(rest.children)}
        </div>
    )
}

FormDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

FormDialog.defaultProps = {
    title: 'Modal Title'
};

export default FormDialog
