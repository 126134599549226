import React, { useState } from 'react'
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    ReferenceInput,
    SelectInput,
    useDataProvider,
    AutocompleteInput,
    useRefresh
} from 'react-admin';
import { Form } from 'react-final-form';

import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const selectStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const AutocompleteInputInDialog = withStyles({
    suggestionsContainer: { zIndex: 2000 },
})(AutocompleteInput);

const PaymentCollectionField = ({record}) => {
    const classes = selectStyles();
    return (
        <>
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {moment(record.collectedDate).format('YYYY-MM-DD')}
                </Typography>
                <Typography variant="h5" component="h2">
                    Reference No : {record.referenceNo}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {record.account ? record.account?.accountName : 'Not set'}
                </Typography>
            </CardContent>
        </Card>
        </>
    )
};

const inputText = choice => `${choice.referenceNo} | ${choice.account ? choice.account?.accountName : 'Not set'}`;

const useStyles = makeStyles({
    scrollPaper: {
      alignItems: 'baseline'  // default center
    }
});

const AddPaymentCollectionButton = ({record}) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    //const form = useForm();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        setLoading(true);
        dataProvider.update('payment-collections', { id: values.paymentCollections, data: { deposit: record.id, isDeposited: true} })
        .then(({ data }) => {
            console.log(data)
            notify(`Successfully Added`, 'info');
            recompute();
        })
        .catch(error => {
            notify('Opss! something went wrong', 'error');
            setLoading(false);
        })
    };

    const recompute = () => {
        dataProvider.recomputeDeposit(
            { id: record.id, data: JSON.stringify({}) }
        )
        .then(({ data }) => {
            refresh();  
            setLoading(false);
        })
        .catch(error => {
            notify('Opss! something went wrong', 'error');
            setLoading(false);
        })
    }

    return (
        <>
            <Button
                color="secondary"
                size="large"
                onClick={handleClick}
                label="Add"
                style={{marginBottom: '10px'}}
            >
                <AddIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={showDialog}
                onClose={handleCloseClick}
                classes={{scrollPaper: classes.scrollPaper }}
            >
                <Form
                    onSubmit={handleSubmit}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogContent>
                            <ReferenceInput
                                fullWidth
                                filter={{status: 'Active', 'exists[deposit]': false, type: record.type }} 
                                label="Payment Collection"
                                source="paymentCollections" reference="payment-collections"
                                filterToQuery={searchText => ({ referenceNo: searchText })}
                                sort={{ field: 'id', order: 'ASC' }}
                                perPage={15}
                                validate={[required()]}
                                resettable>
                                <AutocompleteInputInDialog matchSuggestion={(filterValue, suggestion) => true} optionText={<PaymentCollectionField />} inputText={inputText}/>
                            </ReferenceInput>
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="Cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="Add"
                                    type="submit"
                                    disabled={loading}
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
            </Dialog>
        </>
    )
}

export default AddPaymentCollectionButton
