import React from 'react';
import { Fragment, useCallback, cloneElement, Children, useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput,
    SelectInput,
    NumberField,
    DateInput,
    FunctionField,
    ListContextProvider,
    useQueryWithStore,
    ListToolbar,
    Pagination,
    ResourceContextProvider,
    useGetList,
    Button,
    BulkActionsToolbar,
    useListParams,
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, Chip, Card  } from '@material-ui/core';
import { useMediaQuery, Divider, Tabs, Tab, Theme } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import { parse, stringify } from 'query-string';

import PaymentCollectionView from '../deposits/PaymentCollectionView';
import BulkReviewButton from './BulkReviewButton';
import BulkPostButton from './BulkPostButton';
import NbItemsField from '../deposits/NbItemsField';
import rowStyle from '../deposits/rowStyle';

const useFilterStyles = makeStyles({
    status: { width: 150 },
    bankAccount: { width: 350 }
});

const BankAccountField = ({record}) => {
    return (
        <>
            <span>({record.bankCode}) {record.accountNo} - {record.accountName}</span>
        </>
    )
};

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};


const CustomFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
            
            <DateInput
                alwaysOn
                label="Deposited [After]"
                source="depositedDate[after]"
            />
            <DateInput
                alwaysOn
                label="Deposited [Before]"
                source="depositedDate[before]"
            />
            <ReferenceInput
                fullWidth
                resettable
                filter={{isActive: true, pagination: false}}
                sort={{ field: 'createdAt', order: 'ASC' }}
                abel="Select Bank Account" source="bankAccount" 
                reference="bank-accounts"
                className={classes.bankAccount}>
                <SelectInput optionText={<BankAccountField />} />
            </ReferenceInput>
            <NullableBooleanInput source="hasVariance" label="HasVariance" 
                options={{ 
                    SelectProps: { displayEmpty: true },
                    InputLabelProps: { shrink: true }
                }}/>
            {/* <QuickFilter source="hasVariance" label="HasVariance" defaultValue={true} /> */}
        </Filter>
    )
};


const ListActions = (props) => {

    const {
        filters,
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        setFilters
    } = useListContext();

    return (
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button onClick={() => setFilters({ status: filterValues.status, 'exists[runner]': true})} label="Clear Filters">
                <ClearAllIcon />
            </Button>
            <RefreshButton />
        </TopToolbar>
    );
};

const ListBulkActionButtons = ({ filterValues, ...props}) => {
    return (
        <Fragment>
            
            {filterValues.status === 'Active' && (
                <BulkReviewButton {...props}/>
            )}
            
            {filterValues.status === 'Reviewed' && (
                
                <BulkPostButton {...props}/>
            )}
            
        </Fragment>
    )
};

const tabs = [
    { id: 'Active', name: 'For Review' },
    { id: 'Reviewed', name: 'Reviewed' },
    { id: 'Posted', name: 'Posted' },
];

const useGetTotals = (filterValues) => {
    // const { total: totalActive } = useGetList(
    //     'deposits',
    //     { perPage: 1, page: 1 },
    //     { field: 'id', order: 'ASC' },
    //     { ...filterValues, status: 'Active' }
    // );
    const { total: totalForReview } = useGetList(
        'deposits',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'Active' }
    );
    const { total: totalReviewed } = useGetList(
        'deposits',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'Reviewed', isReviewed: true }
    );
    const { total: totalPosted } = useGetList(
        'deposits',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'Posted', isPosted: true }
    );
    return {
        //Active: totalActive,
        'For Review': totalForReview,
        Reviewed: totalReviewed,
        Posted: totalPosted
    };
};

const styles = {
    status: { textAlign: 'center' },
    depositedDate: { textAlign: 'center' },
    
};

const useStyles = makeStyles(styles);

const CustomDataGrid = ({hasBulkActions, ...props}) => {
    const { 
        selectedRow
    } = props;
    const classes = useStyles();
    return (
        <>
            <Datagrid 
                {...props}
                optimized
                rowClick="expand"
                hasBulkActions={hasBulkActions}
                rowStyle={rowStyle(selectedRow)}
                expand={<PaymentCollectionView />}
                >
                
                <DateField source="depositedDate" label="Date Deposited" cellClassName={classes.depositedDate} headerClassName={classes.depositedDate}/>
                {/* <FunctionField label="Bank Account"  
                    render={record => `(${record.bankAccount.bankCode}) ${record.bankAccount.accountNo} - ${record.bankAccount.accountName}`}
                    /> */}
                {/* <TextField source="remarks" label="Remarks" /> */}
                <TextField source="bankAccount.bankCode" label="Bank Account" />
                <TextField source="type" label="Type" />
                <TextField source="runner.runnerName" label="Runner" />
                <NbItemsField />
                <NumberField source="depositedAmount" label="Deposited Amount"/>
                <NumberField source="totalAmount" label="Collected Amount"/>
            </Datagrid>
                
        </>
    )
}

const TabbedDatagrid = props => {
    const listContext = useListContext();
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    
    const [active, setActive] = useState([]);
    const [forReview, setForReview] = useState([]);
    const [reviewed, setReviewed] = useState([]);
    const [posted, setPosted] = useState([]);
    const totals = useGetTotals(filterValues);

    useEffect(() => {
        if (ids && ids !== filterValues.status) {
            switch (filterValues.status) {
                // case 'Active':
                //     setActive(ids);
                //     break;
                case 'Active':
                    setForReview(ids);
                    break;
                case 'Reviewed':
                    setReviewed(ids);
                    break;
                case 'Posted':
                    setPosted(ids);
                    break;
                default:
                    break;
            }
        }
    }, [ids, filterValues.status]);

    const handleChange = useCallback(
        (event, value) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.name]
                                ? `${choice.name} (${totals[choice.name]})`
                                : choice.name
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
  
            <div>
                {/* {filterValues.status === 'Active' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: active }}
                    >
                        <CustomDataGrid />
                    </ListContextProvider>
                )} */}
                {filterValues.status === 'Active' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: forReview }}
                    >
                        <CustomDataGrid hasBulkActions={true} {...props}/>
                        
                    </ListContextProvider>
                )}
                {filterValues.status === 'Reviewed' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: reviewed }}
                    >
                        <CustomDataGrid hasBulkActions={true} {...props}/>
                    </ListContextProvider>
                )}
                {filterValues.status === 'Posted' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: posted }}
                    >
                        <CustomDataGrid hasBulkActions={false} {...props}/>
                    </ListContextProvider>
                )}
            </div>
            
        </Fragment>
    );
};

const listStyles = makeStyles(
    theme => ({
        main: {
            display: 'flex',
        },
        content: {
            marginTop: 0,
            transition: theme.transitions.create('margin-top'),
            position: 'relative',
            flex: '1 1 auto',
            [theme.breakpoints.down('xs')]: {
                boxShadow: 'none',
            },
            overflow: 'inherit',
        },
        bulkActionsDisplayed: {
            marginTop: -theme.spacing(8),
            transition: theme.transitions.create('margin-top'),
        },
    }),
    { name: 'RaList' }
);

const DepositPostingList = (props) => {
    const classes = listStyles(props);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'createdAt', order: 'DESC' });
    const [currentSort, setCurrentSort] = useState({ field: 'createdAt', order: 'DESC' });
    const [filter, setFilter] = useState({ status: 'Active', 'exists[runner]': true });
    const [displayedFilters, setDisplayedFilters] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [time, setTime] = useState(0);

    const dispatch = useDispatch();
    const history = useHistory();
    
    const { data, total, loaded, loading, error } = useQueryWithStore({
        type: 'getList',
        resource: 'deposits',
        payload: {
            pagination: { page, perPage },
            sort: sort,
            filter: filter,
        }
    });

    const updateSort = (sort, order) => {
        setSort({ field: sort, order: order });
        setCurrentSort({
            field: sort,
            order: order
        });
    }
    
    const updateFilters = (filters) => {
        if(time) clearTimeout(time);
        setTime(setTimeout(() => {
            // const query = { filters };
            // console.log(query);
            // console.log(filters);
            // const newParams = {
            //     page,
            //     perPage,
            //     filter: filters,
            //     displayedFilters
            // };

            // history.push({
            //     search: `?${stringify({
            //         ...newParams,
            //         filter: JSON.stringify(newParams.filter),
            //         displayedFilters: JSON.stringify(
            //             newParams.displayedFilters
            //         ),
            //     })}`,
            // });
            // dispatch(changeListParams('deposits', newParams));

            setFilter(filters);
        }, 500));
    }

    const updateShowFilter = (title, defaultValue) => {
        const newFilter = {[title]: true};
        setDisplayedFilters(prevFilters => ({...prevFilters, ...newFilter }));
    }

    const updateHideFilter = useCallback((title) => {
        const newFilter = omit(filter, [`${title}`]);
        const hideFilter = omit(displayedFilters, [`${title}`]);

        setFilter(newFilter);
        setDisplayedFilters(hideFilter);
    },[filter, displayedFilters])

    const onSelect = (newIds) => {
        if(newIds.length === 0) {
            setSelectedIds([]);
        } else {
            setSelectedIds([...selectedIds, ...newIds]);
        }
    }

    const onToggleItem = (id) => {
        if(selectedIds.includes(id)) {
            const newSelectedIds = selectedIds.filter((n) => n !== id);
            setSelectedIds(newSelectedIds);
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    }

    const onUnselectItems = useCallback(() => {
        setSelectedIds([]);
    },[]);

    return (
        <Fragment>
            <ResourceContextProvider value="deposits">
                <ListContextProvider value={{
                        basePath: '/deposits',
                        data: keyBy(data, 'id'),
                        ids: data ? data.map(({ id }) => id) : [],
                        total,
                        loaded,
                        loading,
                        // pagination
                        page,
                        perPage,
                        setPage,
                        setPerPage,
                        // sorting
                        currentSort: currentSort,
                        setSort: updateSort,
                        // filtering
                        filterValues: filter,
                        setFilters: updateFilters,
                        displayedFilters,
                        showFilter: updateShowFilter,
                        hideFilter: updateHideFilter,
                        // row selection
                        selectedIds: selectedIds,
                        onSelect,
                        onToggleItem,
                        onUnselectItems,
                        // misc
                        resource: 'deposits',
                        
                }}>
                    <ListToolbar filters={<CustomFilter/>} actions={<ListActions />}/>
                    
                    <div className={classes.main}>
                        <Card
                            className={classnames(classes.content, {
                                [classes.bulkActionsDisplayed]: selectedIds.length > 0,
                            })}    
                        > 
                            <BulkActionsToolbar>
                                <ListBulkActionButtons unselectAll={onUnselectItems}
                                {...props}/>
                            </BulkActionsToolbar>
                            <TabbedDatagrid />
                            <Pagination /> 
                        </Card> 
                    </div>
                    
                </ListContextProvider >
            </ResourceContextProvider>
           
        </Fragment>
    )
}

export default DepositPostingList
