import React, {
    useState,
    useCallback,
  } from "react";
  import {
    PasswordInput,
    required,
    useDataProvider,
    useNotify,
    useGetIdentity,
    Title,
    Button,
    Loading
  } from "react-admin";

  import { Form  } from 'react-final-form';
  import arrayMutators from 'final-form-arrays';
  import { Box, Toolbar, Grid, Card, CardContent, CircularProgress } from '@material-ui/core';
  import SaveIcon from '@material-ui/icons/Save';

  const ChangePassword = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState();

    const handleSave = useCallback(
        (values, form) => {
            const data = JSON.stringify(values);

            setSaving(true);
            dataProvider.changePassword(
                { data: data }
            )
            .then(({ data }) => {
                setSaving(false);
                notify("Your password has been updated", "info");

                resetForm(form);
            })
            .catch(e => {
                console.log(e.status);
                setSaving(false);
                notify(
                    "A technical error occured while updating your password. Please try later.",
                    "warning"
                );
                
            });
        },
        [dataProvider, notify]
    );

    const resetForm = (form) => {
        form.setConfig('keepDirtyOnReinitialize', false)
        form.restart()
        form.setConfig('keepDirtyOnReinitialize', true)
    }

    return (
        <>
            {/* <Title title={'Change Password'} />
            <SaveContextProvider value={saveContext}>
                <SimpleForm save={handleSave} >
                    <TextInput source="newPassword" validate={required()} />
                    <TextInput source="confirmPassword" validate={required()} />
                </SimpleForm>
            </SaveContextProvider> */}
            <Title title={'Change Password'} />
            <Form
                onSubmit={handleSave}
                validate={({newPassword, confirmPassword}) => {
                    const errors = {}
            
                    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
                        errors.confirmPassword = 'The password confirmation is not the same as the new password.';
                    }

                    return errors
                }}
                keepDirtyOnReinitialize
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Card>
                                <form onSubmit={handleSubmit} noValidate >
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <PasswordInput autoFocus autoComplete="off" fullWidth source="newPassword" validate={[required()]}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                            <PasswordInput autoComplete="off" fullWidth source="confirmPassword" validate={[required()]}/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Toolbar style={{backgroundColor: '#f5f5f5'}}>
                                        <Box display="flex" justifyContent="space-between" width="100%">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                label="Save Changes"
                                                type="submit"
                                                size="medium"
                                                disabled={saving || pristine}
                                            >
                                                {saving 
                                                    ? <CircularProgress size={25} thickness={2} />
                                                    : <SaveIcon />
                                                }
                                            </Button>
                                        </Box>
                                    </Toolbar>
                                </form>
                            </Card>
                        </Grid>
                    </Grid>
                )}
            />
           
        </>

      );
  }

  export default ChangePassword;