import * as React from 'react';
import NumberFormat from 'react-number-format';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import CardWithIcon from './CardWithIcon';


const TotalCollection = ({ value }) => {
    return (
        <CardWithIcon
            icon={AccountBalanceWalletIcon}
            title={'Total Collections'}
            subtitle={<NumberFormat prefix="₱" decimalScale={2} value={value} displayType={'text'} thousandSeparator={true}/>}
            to=""
        />
    );
};

export default TotalCollection;