import TemplateCreate from './TemplateCreate';
import TemplateEdit from './TemplateEdit';
import TemplateList from './TemplateList';

const Template = {
    list: TemplateList,
    create: TemplateCreate,
    edit: TemplateEdit
}

export default Template;