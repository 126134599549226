import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    SaveButton,
    Toolbar,
    useEditController,
    EditContextProvider
 } from 'react-admin'
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import FromDialog from './FormDialog';

const CustomEditToolbar  = (props) => {
    
    const { 
        basePath,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({data}) => {
        notify(`Successfully Updated`, 'info', {}, true);
        redirect('list', basePath);
        refresh();
    };

    const transform = data => ({
        catName: data.catName,
        catDesc: data.catDesc,
        isActive: data.isActive,
    });

    return (
        <Toolbar {...props}>
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}
                transform={transform}/>
        </Toolbar>
    );

};

const ExpenseCategoryEdit = ({ onCancel, ...props}) => {
    const controllerProps = useEditController(props);
    return (
        <FromDialog onCancel={onCancel} title="Edit Category">
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomEditToolbar />}>
                    
                    <TextInput fullWidth source="catName" label="Category Name" validate={[required()]} />
                    <TextInput fullWidth source="catDesc" rowsMax={15} multiline label="Remarks" validate={minLength(3)}/>
                    <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
                </SimpleForm>
            </EditContextProvider>
        </FromDialog>
    )
}

export default ExpenseCategoryEdit
