import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    useDeleteMany,
    Confirm,
    useRefresh,
    useNotify,
    useUnselectAll,
    CRUD_DELETE_MANY,
} from 'react-admin';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

import ActionDelete from '@material-ui/icons/Delete';

const useStyles = makeStyles(
    theme => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaBulkDeleteWithConfirmButton' }
);


const BulkDeleteButton = ({
    selectedIds,
    ...props
}) => {
    const {
        resource,
        onClick
    } = props;
    
    const classes = useStyles(props);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const [open, setOpen] = useState(false);

    const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
        action: CRUD_DELETE_MANY,
        onSuccess: () => {
            refresh();
            notify(`${selectedIds.length} item(s) successfully deleted`, 'info', {
                smart_count: selectedIds.length,
            });
            unselectAll(resource);
        },
        onFailure: () => {
            notify(
                'Error! Country not deleted',
                'warning'
            );
        },
    });

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => setOpen(false);

    const handleDelete = (e) => {
        deleteMany();

        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    return (
        <Fragment>
            <Button  className={classes.deleteButton} label="Delete selected" onClick={handleClick}>
                <ActionDelete /> 
            </Button >
            <Confirm
                isOpen={open}
                loading={loading}
                title="Confirm Bulk Delete"
                content={`Are you sure you want to delete these ${selectedIds.length} item(s)?`}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteButton;