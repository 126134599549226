import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Title, useNotify, useRedirect, fetchStart, fetchEnd } from 'react-admin';
import { useDataProvider, Loading, Error  } from 'react-admin';
import { 
    sanitizeEmptyValues,
    ReferenceInput,
    SelectInput,
    required,
    SaveButton,
    minLength,
    TextInput,
    Button,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    FormDataConsumer,
    Confirm,
    useCreate
} from 'react-admin';
import { Form  } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Typography, Box, Toolbar, Grid } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';
import RichTextInput from 'ra-input-rich-text';

import TemplateButton from './TemplateButton';
import ClearButton from '../send-sms/ClearButton';

const SendEMail = () => {
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState();
    const [title, setTitle] = useState('Confirm Send');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notify = useNotify();
    const dispatch = useDispatch();


    const submit = values => {

        const title = 'Send To : ' + values.subscriber_ids.length + ' ' + ((values.subscriber_ids.length === 1) ? 'account' : 'accounts');
        setFormValues(values);
        setTitle(title);
        setOpen(true);
    };

    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
    };

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = useCallback(() => {

            const values = sanitizeEmptyValues( {}, formValues);

            const data = JSON.stringify({
                subscribers: values.subscriber_ids,
                emailContent: values.emailContent,
                subject: values.subject
            });

            setIsSubmitting(true);
            dataProvider.sendBulkEmail(
                { data: data }
            )
            .then(({ data }) => {
                setOpen(false);
                notify('Successfully submitted for queue');
            })
            .catch(e => {
                console.log(e.status);
                notify(
                    "A technical error occured. Please try again later.",
                    "warning"
                );
                
            })
             .finally(() => {
                setIsSubmitting(false);
            });

        },
        [dataProvider, notify, formValues]
    );

    return (
        <>
            <Box display="flex" justifyContent="space-between" width="100%" style={{marginBottom: 10, marginTop: 10}}>
                <Typography variant="h6" gutterBottom>
                    Compose
                </Typography>
            </Box>
           
        
            <Card>
                <Title title={'Send EMail'} />
                <CardContent>
                    <Form
                        onSubmit={submit}
                        mutators={{ ...arrayMutators }}
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        keepDirtyOnReinitialize
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <ReferenceInput required 
                                            filter={{isActive: true, pagination: false}}
                                            sort={{ field: 'createdAt', order: 'ASC' }} label="Select Network Leader" source="leader" reference="leaders" validate={[required()]}>
                                            <SelectInput fullWidth optionText="leaderName" />
                                        </ReferenceInput>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            formData.leader && (
                                                <ReferenceArrayInput 
                                                    filter={{isActive: true, status: 'Confirmed', leader: formData.leader}}
                                                    sort={{ field: 'createdAt', order: 'DESC' }}
                                                    label="Send To :" 
                                                    source="subscriber_ids" reference="accounts"
                                                    resource="accounts"
                                                    filterToQuery={searchText => ({ accountName: searchText })}
                                                    validate={[required()]}>
                                                        <AutocompleteArrayInput fullWidth optionText="accountName" />
                                                </ReferenceArrayInput>
                                            )
                                        )}
                                        </FormDataConsumer>
                                       
                                    </Grid>
                                </Grid>
                                <Divider light />
                                <Grid container spacing={2}  style={{ marginTop: 16 }}>
                                    <Grid item xs={6}>
                                    <TextInput fullWidth resettable source="subject" label="Subject" validate={[required()]}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={11}>
                                        <RichTextInput fullWidth 
                                            source="emailContent" label="Type your message or Select from template" validate={[required(), minLength(3)]}
                                        />
                                    
                                    </Grid>
                                    <Grid item xs={1} style={{ marginTop: 54 }}>
                                        <TemplateButton />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item style={{ marginTop: 16 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            label="Send Now"
                                            type="submit"
                                        >
                                            <SendIcon />
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}>
                                        <ClearButton/>
                                    </Grid>
                                    
                                </Grid>
                                
                            </form>
                        )}
                    />
                </CardContent>
            </Card>

            <Confirm
                loading={isSubmitting}
                isOpen={open}
                title={title}
                content="Are you sure you want to Send Email?"
                confirm="Yes"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    )
    
}

export default SendEMail
