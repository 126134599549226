import * as React from 'react';
import { FunctionField } from 'react-admin';

const render = (record) => record && record.paymentCollections.length;

const NbItemsField = ({ record }) => (
    <FunctionField record={record} render={render} />
);

NbItemsField.defaultProps = {
    label: 'Payments',
    textAlign: 'center',
};

export default NbItemsField;