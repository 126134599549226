import RunnerList from './RunnerList';
import RunnerCreate from './RunnerCreate';
import RunnerEdit from './RunnerEdit';

const Runner = {
    list: RunnerList,
    create: RunnerCreate,
    edit: RunnerEdit
}

export default Runner;