import React, { useState } from 'react'
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    ReferenceInput,
    SelectInput,
    useDataProvider,
} from 'react-admin';
import { Form } from 'react-final-form';

import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import FindInPageIcon from '@material-ui/icons/FindInPage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const SearchTemplateButton = () => {

    const [showDialog, setShowDialog] = useState(false);
    const form = useForm();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        console.log(values);
        setLoading(true);
        dataProvider.getOne('users', { id: values.templateId })
        .then(({ data }) => {
            console.log(data)
            setShowDialog(false);
            form.change('emailContent', data.templateContent);
            setLoading(false);
        })
        .catch(error => {
            notify('Opss! something went wrong', 'error');
            setLoading(false);
        })
    };

    return (
        <>
            <Button
                color="secondary"
                size="large"
                onClick={handleClick}
            >
                <FindInPageIcon style={{fontSize: 30}}/>
            </Button>

            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={showDialog}
                onClose={handleCloseClick}
            >
                <Form
                    onSubmit={handleSubmit}
                    render={({
                        handleSubmit,
                        pristine,
                        submitting
                    }) => (
                        <>
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogContent>
                            <ReferenceInput required fullWidth 
                                filter={{isActive: true, pagination: false, type: 'EMAIL'}}
                                sort={{ field: 'createdAt', order: 'ASC' }} label="Template" source="templateId" reference="templates" validate={[required()]}>
                                <SelectInput optionText="label" />
                            </ReferenceInput>
                            </DialogContent>
                            <DialogActions style={{padding: '8px 24px'}}>
                                <Button
                                    color={'default'}
                                    label="Cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                    size={"medium"}
                                >
                                    <CancelIcon />
                                </Button>
                                <Button
                                    label="OK"
                                    type="submit"
                                    disabled={loading}
                                    size={"medium"}
                                    variant="contained"
                                >
                                    <CheckCircleIcon/>
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                    )}
                />
            </Dialog>
        </>
    )
}

export default SearchTemplateButton
