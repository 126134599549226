import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    SaveButton,
    Toolbar,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useEditController,
    EditContextProvider,
    usePermissions
 } from 'react-admin'
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import FromDialog from './FormDialog';
import { hasRole } from '../../security/helper';

const CustomEditToolbar  = (props) => {
    
    const { 
        basePath,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({data}) => {
        notify(`Successfully Updated`, 'info', {}, true);
        redirect('list', basePath);
        refresh();
    };

    const transform = data => ({
        leaderName: data.leaderName,
        remarks: data.remarks,
        isActive: data.isActive,
        runners: data.runners,
        username: data.username
    });

    return (
        <Toolbar {...props}>
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}
                transform={transform}/>
        </Toolbar>
    );

};


const LeaderEdit = ({ onCancel, ...props}) => {
    const controllerProps = useEditController(props);
    const { permissions } = usePermissions();

    return (
        <FromDialog onCancel={onCancel} title="Edit Leader">
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomEditToolbar />}>
                    
                    <TextInput fullWidth autoFocus source="leaderName" rowsMax={15} multiline label="Leader Name" validate={[required()]}/>
                    <ReferenceArrayInput fullWidth
                        label="Runners"
                        source="runners" 
                        reference="runners"
                        filter={{ isActive: true }}
                        sort={{ field: 'createdAt', order: 'ASC' }}
                        filterToQuery={searchText => ({ runnerName: searchText })}
                    >
                        <AutocompleteArrayInput fullWidth optionText="runnerName" 
                            options={{
                                suggestionsContainerProps: {
                                  style: {
                                    zIndex: 2000
                                  }
                                }
                              }}
                        />
                    </ReferenceArrayInput>
                    {/* <TextInput fullWidth source="remarks" rowsMax={15} multiline label="Remarks" validate={minLength(3)}/> */}
                    {hasRole(permissions, "ROLE_SUPER_ADMIN") &&
                        <TextInput source="username" label="Username"/>
                    }
                    <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
                </SimpleForm>
            </EditContextProvider>
        </FromDialog>
    )
}

export default LeaderEdit
