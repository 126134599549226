import React from 'react'
import { 
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    NumberInput,
    SaveButton,
    Toolbar,
    ListButton,
    TopToolbar
 } from 'react-admin'
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


const CustomEditActions = (props) => {
    const { basePath } = props;
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
        </TopToolbar>
    )
};

const CustomEditToolbar  = (props) => {
    
    const { 
        basePath,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({data}) => {
        notify(`Successfully Updated`, 'info', {}, true);
        redirect('list', basePath);
        refresh();
    };

    const transform = data => ({
        offeringName: data.offeringName,
        offeringValue: data.offeringValue,
        isActive: data.isActive
    });

    return (
        <Toolbar {...props}>
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}
                transform={transform}/>
        </Toolbar>
    );

};

const OfferingEdit = (props) => {
    return (
        <Edit title="Edit Offering" {...props}  actions={<CustomEditActions />}>
            <SimpleForm
                toolbar={<CustomEditToolbar />}>
                
                <TextInput style={{width:'50%'}} autoFocus source="offeringName" rowsMax={15} multiline label="Name" validate={[required()]}/>
                <NumberInput source="offeringValue" label="Amount" validate={required()}/>
                <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
            </SimpleForm>
        </Edit>
    )
}

export default OfferingEdit
