import PaymentCreate from "./PaymentCreate";
import PaymentEdit from "./PaymentEdit";
import PaymentList from "./PaymentList";

const Payment = {
    list: PaymentList,
    create: PaymentCreate,
    edit: PaymentEdit
}

export default Payment;