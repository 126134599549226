import {cloneElement} from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    maxLength,
    Toolbar,
    TopToolbar,
    ListButton,
    SaveButton,
    useEditController,
    EditContextProvider
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
// const CustomEditTopToolbar = (props) => {
//     const { basePath } = props;
//     return (
//         <TopToolbar>
//             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
//         </TopToolbar>
//     )
// };

// const toolbarStyles = makeStyles({
//     toolbar: {
//         display: 'flex',
//         justifyContent: 'space-between',
//     },
// });

const CustomEditToolbar  = (props) => {
    const { 
        basePath,
        handleSubmitWithRedirect,
        invalid,
        saving,
        pristine,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({data}) => {
        notify(`Successfully Updated`, 'info');
        redirect('list', basePath);
        refresh();
    };

    return (
        <Toolbar {...props}>
            <SaveButton 
                disabled={pristine}
                onSuccess={onSuccess}/>
        </Toolbar>
    );

};



export const styles = {
    root: {
        paddingTop: 0,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0em 1em 0em 1em',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none'
    },
};

const useStyles = makeStyles(styles);

const CustomEdit = props => {
    const controllerProps = useEditController(props);
    console.log(controllerProps);
    const {
        basePath, // deduced from the location, useful for action buttons
        loaded, // boolean that is false until the record is available
        loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;
    
    return (
        <EditContextProvider value={controllerProps}>
            {cloneElement(props.children, {
                basePath,
                record,
                redirect,
                resource,
                save,
                saving,
                version,
            })}
        </EditContextProvider>
    );
}

const CountryEdit = ({ onCancel, ...props}) => {
    const classes = useStyles();
    const theme = useSelector((state) =>
        state.theme
    );
    console.log(theme);
    return (
        <div className={classes.root}>
            <Paper square  className={classes.title}>
                <Typography variant="h6">
                    Edit Country
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </Paper>
            <CustomEdit undoable={false} {...props}>
                <SimpleForm
                    toolbar={<CustomEditToolbar />} >
                        <TextInput autoFocus source="countryCode" label="Code" validate={[required(), maxLength(2, 'Should be at most 2 characters')]}/>
                        <TextInput source="countryName" label="Country Name" validate={required()}/>
                </SimpleForm>
            </CustomEdit>
        </div>
    )

};

export default CountryEdit;