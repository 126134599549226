import React, { PureComponent } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const random = () => {
    const min = 1;
    const max = 10000;
    const rand = min + Math.random() * (max - min);

    return rand;
}

const data = [
    {
        name: 'October 2020', collection: random(), expense:  random(),
    },
    {
        name: 'November 2020', collection:  random(), expense:  random(),
    },
    {
        name: 'December 2020', collection:  random(), expense:  random(),
    },
    {
        name: 'January 2021', collection:  random(), expense:  random(), 
    },
    {
        name: 'February 2021', collection:  random(), expense:  random(), 
    },
  ];

const CollectionExpenseChart = () => {
    return (
        <Card>
            <CardHeader title={'Collection Vs Expense'} />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <BarChart
                            data={data}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                            <YAxis axisLine={false} tickLine={false}/>
                            <Tooltip 
                                formatter={value =>
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'PHP',
                                    }).format(value)
                                }
                            />
                            <Legend height={36} layout="horizontal" verticalAlign="top" align="right" />
                            <Bar dataKey="collection" fill="#4864F3" />
                            <Bar dataKey="expense" fill="#F4B400" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}

export default CollectionExpenseChart
