import {every, isUndefined, includes} from 'lodash';


export function isString(value) {
    return !!value && typeof value === 'string';
}

export function transformStringToArray(value) {
    if (isString(value)) {
        return [value];
    }
    return value;
}


export const hasPermission = (permissions, askedPermissions) =>
{
    permissions = permissions?.permissions;

    askedPermissions = transformStringToArray(askedPermissions);
 
    if(isUndefined(permissions)) return false;

    return every(askedPermissions, (element) => includes(permissions, element));
}

export const hasRole = (permissions, askedPermissions) =>
{
    permissions = permissions?.roles;

    askedPermissions = transformStringToArray(askedPermissions);
 
    if(isUndefined(permissions)) return false;

    return every(askedPermissions, (element) => includes(permissions, element));
}