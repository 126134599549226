import AccountCreate from "./AccountCreate";
import AccountEdit from "./AccountEdit";
import AccountList from "./AccountList";

const Account = {
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit
}

export default Account;