import React from 'react';
import { Fragment, useCallback } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    SearchInput,
    ReferenceArrayField,
    ChipField,
    SingleFieldList
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { makeStyles } from '@material-ui/core/styles';

import LeaderEdit from './LeaderEdit';
import LeaderCreate from './LeaderCreate';

const LeaderFilter = props => (
    <Filter {...props}>
        <SearchInput placeholder="Search Leader Name" id="sleaderName" source="leaderName" alwaysOn />
    </Filter>
);

const ListActions = ({basePath}) => {
    return (
        <TopToolbar>
            <CreateButton basePath={basePath}/>
            <RefreshButton />
        </TopToolbar>
    );
};

export const styles = {
    isActive: { width: '10%', textAlign: 'center' },
    name: { width: '25%' },
    username: { width: '10%'},
    runners: { width: '35%'},
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    }
};

const useStyles = makeStyles(styles);
const LeaderList = (props) => {
    const { 
        basePath
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info', {},true);
        redirect('list', basePath);
        refresh();
    }

    const handleClose = useCallback(() => {
        history.push('/leaders');
    }, [history]);

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Leaders"
                actions={<ListActions/>}
                filters={<LeaderFilter />}
                perPage={25}
                sort={{ field: 'id', order: 'DESC' }}>
                <Datagrid>
                    <TextField source="leaderName" label="Leader Name" cellClassName={classes.name}/>
                    
                    <ReferenceArrayField
                        label="Runners"
                        reference="runners"
                        source="runners"
                        sort={{ field: 'id', order: 'ASC' }}
                        cellClassName={classes.runners}
                    >
                        <SingleFieldList>
                            <ChipField source="runnerName" size="small" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField source="username" label="Username" cellClassName={classes.username}/>
                    <BooleanField source="isActive" label="IsActive" cellClassName={classes.isActive} headerClassName={classes.isActive} className={classes.alignCenter}/>
                    <EditButton />
                    <DeleteButton onSuccess={onSuccessDelete}/>
                </Datagrid>
            </List>
            <Route path="/leaders/create">
                {({ match }) => (
                    <Dialog
                        fullWidth
                        open={!!match}
                        maxWidth={'xs'}
                        onClose={handleClose}
                        scroll={'body'}
                        disableBackdropClick={true}
                    >
                        <DialogContent style={{padding: '0px'}}>
                            <LeaderCreate
                                onCancel={handleClose}
                                {...props}
                            />
                        </DialogContent>
                    
                    </Dialog>
                )}
            </Route>
            <Route path="/leaders/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        );

                        return (
                            <Dialog
                                fullWidth
                                open={isMatch}
                                maxWidth={'xs'}
                                onClose={handleClose}
                                scroll={'body'}
                                disableBackdropClick={true}
                            >
                                <DialogContent style={{padding: '0px'}}>
                                    {isMatch ? (
                                        <LeaderEdit
                                            id={isMatch ? decodeURIComponent(match.params.id) : null}
                                            onCancel={handleClose}
                                            {...props}
                                        />
                                    ) : (
                                        null
                                    )}
                                </DialogContent>
                            
                            </Dialog>
                        );
                    }}
                </Route>
        </Fragment>
    )
}

export default LeaderList
