import React, {
    useCallback,
  } from 'react'
  import PropTypes from 'prop-types'
  import classnames from 'classnames'
  import { Button } from 'react-admin'
  import { useForm } from 'react-final-form'
  import { makeStyles } from '@material-ui/core/styles'
  import IconClear from '@material-ui/icons/Clear'
  
  const useStyles = makeStyles(theme => ({
    button: { 
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }), { name: 'ClearButton' })
  
  const sanitizeRestProps = ({
    basePath,
    invalid,
    pristine,
    //reset,
    saving,
    submitOnEnter,
    handleSubmit,
    handleSubmitWithRedirect,
    undoable,
    onSave,
    ...rest
  }) => rest
  
  const ClearButton = ({ className, icon, ...rest}) => {
    const classes = useStyles()
    const form = useForm()
  
    const handleClick = useCallback(() => {
      form.setConfig('keepDirtyOnReinitialize', false)
      form.restart()
      form.setConfig('keepDirtyOnReinitialize', true)
    }, [form])
  
    return (
      <Button
        className={classnames(classes.button, className)}
        onClick={handleClick}
        {...sanitizeRestProps(rest)}
      >
        { icon ? React.cloneElement(icon) : null }
      </Button>
    )
  }
  
  ClearButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    icon: PropTypes.element,
  }
  
  ClearButton.defaultProps = {
    icon: <IconClear />,
    label: 'Clear',
  }
  
  export default ClearButton