import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import SecurityIcon from '@material-ui/icons/Security';
import CustomAppBar from './CustomAppBar';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={'Configuration'}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const ChangePasswordMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/change-password"
            primaryText={'Change Password'}
            leftIcon={<SecurityIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ChangePasswordMenu />
        <ConfigurationMenu />
    </UserMenu>
);

const MyAppBar = (props) => {
    const classes = useStyles();
    return (
        <CustomAppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            
            {/* <Logo />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} /> */}
        </CustomAppBar>
    );
};

export default MyAppBar;