import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { createBrowserHistory } from 'history'
import dataProviderFactory from './dataProvider';
import authProvider from './security/authProvider';
import { Login, Layout } from './layout';
import themeReducer from './themeReducer';
import customRoutes from './routes';

import { HydraAdmin} from '@api-platform/admin';

import { Dashboard } from './pages/dashboard';

import accounts from './pages/accounts';
import payments from './pages/payments';
import paymentItems from './pages/payment-items';
import countries from './pages/countries';
import offerings from './pages/offerings';
import leaders from './pages/leaders';
import runners from './pages/runners';
import templates from './pages/sms-email/templates';
import groups from './pages/sms-email/groups';
import subscribers from './pages/sms-email/subscribers';
import bankAccounts from './pages/bank-accounts';
import expenseCategories from './pages/expense-categories';
import expenses from './pages/expenses';
import paymentCollections from './pages/payment-collections';
import deposits from './pages/deposits';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT || '';

function App() {
  const [dataProvider, setDataProvider] = useState(null);
  useEffect(() => {
      let restoreFetch;

      const fetchDataProvider = async () => {
          const dataProviderInstance = await dataProviderFactory(
              process.env.REACT_APP_DATA_PROVIDER || ''
          );
          setDataProvider(
              // GOTCHA: dataProviderInstance can be a function
              () => dataProviderInstance
          );
      };

      fetchDataProvider();

      return restoreFetch;
  }, []);

  if (!dataProvider) {
    return (
        <div className="loader-container">
            <div className="loader">Loading...</div>
        </div>
    );
  }

  const history = createBrowserHistory({ basename: '/' });
  
  return (

    <HydraAdmin
      title=" "
      entrypoint={entrypoint}
      history={history}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry>
        <Resource name="accounts" {...accounts}/>
        <Resource name="payment-collections" {...paymentCollections}/>
        <Resource name="deposits" {...deposits}/>
        <Resource name="payments" {...payments}/>
        <Resource name="payment-items" {...paymentItems}/>
        <Resource name="leaders" {...leaders}/>
        <Resource name="runners" {...runners}/>
        <Resource name="offerings" {...offerings}/>
        <Resource name="countries" {...countries}/>
        <Resource name="bank-accounts" {...bankAccounts}/>
        <Resource name="expenses" {...expenses}/>
        <Resource name="expense-categories" {...expenseCategories}/>

        {/* <Resource name="groups" {...groups}/>
        <Resource name="subscribers" {...subscribers}/> */}
        <Resource name="templates" {...templates}/>
    </HydraAdmin>
  );
}

export default App;
