import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    Toolbar,
    SaveButton,
    useEditController,
    minLength,
    EditContextProvider,
    DateInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    AutocompleteInput 
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 
 import FromDialog from './FormDialog';

 const CustomEditToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Updated`, 'info', {}, true);
         redirect('list', basePath);
         refresh();
     };

     const transform = data => ({
        expenseDate: data.expenseDate,
        referenceNo: data.referenceNo,
        category: data.category,
        description: data.description,
        amount: data.amount,
    });

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}
                 transform={transform}/>
         </Toolbar>
     );
 
};

const ExpenseEdit = ({ onCancel, ...props}) => {

    const controllerProps  = useEditController(props);

    return (
        <FromDialog onCancel={onCancel} title="Edit Expense">
            <EditContextProvider value={controllerProps}>
                <SimpleForm 
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomEditToolbar />}
                >

                    <DateInput fullWidth source="expenseDate" label="Date" validate={[required()]} />
                    <TextInput fullWidth source="referenceNo" label="Reference No" />
                    <ReferenceInput required
                        defaultValue="" 
                        fullWidth
                        filter={{isActive: true, pagination: false}}
                        sort={{ field: 'createdAt', order: 'ASC' }} 
                        label="Select Category" 
                        source="category" 
                        reference="expense-categories" 
                        validate={[required()]}
                        format={v => v ? v['@id'] || v : ''}>
                        <SelectInput  optionText="catName"/>
                    </ReferenceInput>
                    <TextInput fullWidth source="description" label="Decription" validate={minLength(3)}/>
                    <NumberInput fullWidth source="amount" label="Amount" validate={[required()]}/>
                </SimpleForm>
            </EditContextProvider>
        </FromDialog>
    )
}

export default ExpenseEdit
