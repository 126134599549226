import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    Toolbar,
    SaveButton,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useCreateController,
    CreateContextProvider,
    usePermissions
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 
 import FromDialog from './FormDialog';

 import { hasRole } from '../../security/helper';

 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Created`, 'info');
         redirect('list', basePath);
         refresh();
     };
     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
};

const LeaderCreate = ({ onCancel, ...props}) => {
    const controllerProps  = useCreateController(props);
    const { permissions } = usePermissions();
    
    return (
        <FromDialog onCancel={onCancel} title="Add Leader">
            <CreateContextProvider value={controllerProps}>
                <SimpleForm 
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomToolbar />}
                >
                    <TextInput fullWidth autoFocus source="leaderName" rowsMax={15} multiline label="Leader Name" validate={[required()]}/>
                    <ReferenceArrayInput fullWidth
                        label="Runners"
                        source="runners" 
                        reference="runners"
                        filter={{ isActive: true }}
                        sort={{ field: 'createdAt', order: 'ASC' }}
                        filterToQuery={searchText => ({ runnerName: searchText })}
                    >
                        <AutocompleteArrayInput fullWidth optionText="runnerName" 
                            options={{
                                suggestionsContainerProps: {
                                    style: {
                                    zIndex: 2000
                                    }
                                }
                                }}
                        />
                    </ReferenceArrayInput>
                    {/* <TextInput fullWidth source="remarks" rowsMax={15} multiline label="Remarks" validate={minLength(3)}/> */}
                    {hasRole(permissions, "ROLE_SUPER_ADMIN") &&
                        <TextInput source="username" label="Username"/>
                    }
                    
                    <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
                </SimpleForm>
            </CreateContextProvider>
        </FromDialog>
    )
}

export default LeaderCreate
