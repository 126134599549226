import React from 'react';
import { Fragment, cloneElement } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';


const SubscriberFilter = props => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn placeholder="Search Name"/>
        <NullableBooleanInput source="isActive" label="IsActive" 
            options={{ 
                SelectProps: { displayEmpty: true },
                InputLabelProps: { shrink: true }
            }}/>
        <ReferenceInput 
            filter={{isActive: true}} 
            source="groupId" reference="groups"
            filterToQuery={searchText => ({ groupName: searchText })}>
            <AutocompleteInput optionText="groupName" />
        </ReferenceInput>
    </Filter>
);

const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <RefreshButton />
        </TopToolbar>
    );
};

const EmailMobileField = (props) => {

    return (
        <>
        <div>
            <TextField source="mobileNo" {...props}/>
        </div>
        
        <div>
            <EmailField  source="email" {...props}/>
        </div>
        </>
    )
};

EmailMobileField.defaultProps = { label: 'Email/Mobile' };

export const styles = {
    isActive: { width: '5%', textAlign: 'center' },
    name: { width: '25%' },
    email: { width: '15%'},
    group: { width: '20%' },
    mobileNo: { width: '10%'},
    createdAt: { width: '15%', textAlign: 'center' },
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    },
};

const useStyles = makeStyles(styles);
const SubscriberList = (props) => {
    const { 
        basePath
    } = props;

    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info', {},true);
        redirect('list', basePath);
        refresh();
    }

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Subscribers"
                actions={<ListActions/>}
                filters={<SubscriberFilter />}
                perPage={25}
                sort={{ field: 'createdAt', order: 'DESC' }}>
                <Datagrid>
                    <TextField source="name" label="Name" cellClassName={classes.name}/>
                    <EmailMobileField cellClassName={classes.email}/>
                    {/* <ReferenceField cellClassName={classes.group} sortBy="groupId.groupName" source="groupId" label="Group" reference="groups"  >
                        <TextField source="groupName"/>
                    </ReferenceField> */}
                    <TextField source="groupId.groupName" label="Group"/>
                    <DateField source="createdAt" showTime={true} label="CreatedAt" cellClassName={classes.createdAt} headerClassName={classes.createdAt}/>
                    <BooleanField source="isActive" label="IsActive" cellClassName={classes.isActive} headerClassName={classes.isActive} className={classes.alignCenter}/>
                    <EditButton />
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default SubscriberList
