import React from 'react';
import { Fragment, useCallback, cloneElement, Children } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput,
    SelectInput,
    NumberField,
    DateInput,
    FunctionField,
    NumberInput,
    usePermissions
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { makeStyles, Chip } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment  } from '@material-ui/core';
import { hasRole } from '../../security/helper';
import DepositCreate from './DepositCreate';
import DepositEdit from './DepositEdit';
import PaymentCollectionView from './PaymentCollectionView';
import NbItemsField from './NbItemsField';
import rowStyle from './rowStyle';

const useFilterStyles = makeStyles({
    status: { width: 150 },
    bankAccount: { width: 350 }
});

const BankAccountField = ({record}) => {
    return (
        <>
            <span>({record.bankCode}) {record.accountNo} - {record.accountName}</span>
        </>
    )
};

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};

const CustomFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
            
            <DateInput
                alwaysOn
                label="Deposited [After]"
                source="depositedDate[after]"
            />
            <DateInput
                alwaysOn
                label="Deposited [Before]"
                source="depositedDate[before]"
            />
           <ReferenceInput
                fullWidth
                resettable
                filter={{isActive: true, pagination: false}}
                sort={{ field: 'createdAt', order: 'ASC' }}
                abel="Select Bank Account" source="bankAccount" 
                reference="bank-accounts"
                className={classes.bankAccount}>
                <SelectInput optionText={<BankAccountField />} />
            </ReferenceInput>
            <NullableBooleanInput source="hasVariance" label="HasVariance" 
                options={{ 
                    SelectProps: { displayEmpty: true },
                    InputLabelProps: { shrink: true }
                }}/>
            {/* <QuickFilter source="hasVariance" label="HasVariance" defaultValue={true} /> */}
            <NumberInput
                label="Deposited Amount"
                source="depositedAmount"
            />
            <ReferenceInput
                filter={{isActive: true, pagination: false}} 
                label="Runner"
                source="runner" reference="runners"
                filterToQuery={searchText => ({ runnerName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}>
                <SelectInput optionText="runnerName"/>
            </ReferenceInput>
            <SelectInput
                source="type"
                choices={[
                    { id: 'CSH', name: 'Cash' },
                    { id: 'CHK', name: 'Check' },
                    { id: 'BNT', name: 'Bank Transfer' }
                ]}
                className={classes.status}
            />
            <SelectInput
                source="status"
                choices={[
                    { id: 'Active', name: 'Active' },
                    { id: 'Reviewed', name: 'Reviewed' },
                    { id: 'Posted', name: 'Posted' },
                ]}
                className={classes.status}
            />
    </Filter>
    )
};


const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} label="Add Deposit Slip"/>
            <RefreshButton />
        </TopToolbar>
    );
};

const useListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
    
});

const ListActionToolbar = ({ children, ...props }) => {
    const classes = useListActionToolbarStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

const DepositEditButton = ({ record, basePath, ...props }) => {
    const { permissions } = usePermissions();

    return (
        <EditButton basePath={basePath} record={record} disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ? false : record.isPosted } />
    )
};

const DepositDeleteButton = ({ record, basePath, ...props }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    
    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info');
        redirect('list', basePath);
        refresh();
    }

    return (
        <DeleteButton basePath={basePath} record={record} disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ? false : record.isPosted } onSuccess={onSuccessDelete} mutationMode="pessimistic" confirmTitle="Confirm Delete"/>
    )
}

const styles = {
    status: { textAlign: 'center' },
    depositedDate: { textAlign: 'center' },
    
};

const useStyles = makeStyles(styles);
const DepositList = (props) => {
    const { 
        selectedRow
    } = props;

    const classes = useStyles();

    const history = useHistory();

    const { 
        basePath,
    } = props;

    const handleClose = useCallback(() => {
        history.push('/deposits');
    }, [history]);

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Deposit Slips"
                bulkActionButtons={false}
                actions={<ListActions/>}
                filters={<CustomFilter />}
                perPage={25}
                sort={{ field: 'createdAt', order: 'DESC' }}>
                <Datagrid 
                    optimized
                    rowStyle={rowStyle(selectedRow)}
                    rowClick="expand"
                    expand={<PaymentCollectionView />}
                    >
                    
                    <DateField source="depositedDate" label="Date Deposited" cellClassName={classes.depositedDate} headerClassName={classes.depositedDate}/>
                    {/* <TextField source="depositNo" label="Deposit Slip No"  /> */}
                    {/* <FunctionField label="Bank Account"  
                        render={record => `(${record.bankAccount.bankCode}) ${record.bankAccount.accountNo} - ${record.bankAccount.accountName}`}
                        /> */}
                    {/* <TextField source="remarks" label="Remarks" /> */}
                    <TextField source="bankAccount.bankCode" label="Bank Account" />
                    <TextField source="type" label="Type" />
                    <TextField source="runner.runnerName" label="Runner" />
                    <NbItemsField />
                    <NumberField source="depositedAmount" label="Deposited Amount"/>
                    <NumberField source="totalAmount" label="Collected Amount"/>
                    <TextField source="status" label="Status" cellClassName={classes.status} headerClassName={classes.status}/>
                    <ListActionToolbar >
                        <DepositEditButton />
                        <DepositDeleteButton />
                    </ListActionToolbar>
                </Datagrid>
            </List>
            <Route path="/deposits/create">
                {({ match }) => (
                    <Dialog
                        fullWidth
                        open={!!match}
                        maxWidth={'sm'}
                        onClose={handleClose}
                        scroll={'body'}
                        disableBackdropClick={true}
                    >
                        <DialogContent style={{padding: '0px'}}>
                            <DepositCreate
                                onCancel={handleClose}
                                {...props}
                            />
                        </DialogContent>
                    
                    </Dialog>
                )}
            </Route>
            <Route path="/deposits/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create'
                    );

                    return (
                        <Dialog
                            fullWidth
                            open={isMatch}
                            maxWidth={'md'}
                            onClose={handleClose}
                            scroll={'body'}
                            disableBackdropClick={true}
                        >
                            <DialogContent style={{padding: '0px'}}>
                                {isMatch ? (
                                    <DepositEdit
                                        id={isMatch ? decodeURIComponent(match.params.id) : null}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : (
                                    null
                                )}
                            </DialogContent>
                        
                        </Dialog>
                    );
                }}
            </Route>
        </Fragment>
    )
}

export default DepositList
