import React from 'react';
import { Fragment, useCallback, cloneElement, Children } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    BulkDeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput,
    SelectInput,
    NumberField,
    DateInput,
    FunctionField,
    NumberInput,
    usePermissions
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment  } from '@material-ui/core';
import { hasRole } from '../../security/helper';

import PaymentCollectionCreate from './PaymentCollectionCreate';
import PaymentCollectionEdit from './PaymentCollectionEdit';

const useFilterStyles = makeStyles({
    status: { width: 150 },
    accountName: { width: 350 }
});

const AccountFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
            <TextInput
                alwaysOn
                label="Reference No."
                source="referenceNo"
                resettable
            />
            <ReferenceInput
                filter={{isActive: true}} 
                label="Account"
                source="account" reference="accounts"
                filterToQuery={searchText => ({ accountName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}
                className={classes.accountName}>
                <AutocompleteInput optionText="accountName"/>
            </ReferenceInput>
            <ReferenceInput
                filter={{isActive: true}} 
                label="Network Leader"
                source="leader" reference="leaders"
                filterToQuery={searchText => ({ leaderName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}
                className={classes.leaderName}>
                <AutocompleteInput optionText="leaderName"/>
            </ReferenceInput>
            <ReferenceInput
                filter={{isActive: true, pagination: false}} 
                label="Runner"
                source="runner" reference="runners"
                filterToQuery={searchText => ({ runnerName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}>
                <SelectInput optionText="runnerName"/>
            </ReferenceInput>
            <NumberInput
                label="Paid Amount"
                source="paidAmount"
            />
            <DateInput
                label="Date Collected"
                source="collectedDate"
            />
             <NullableBooleanInput source="isDeposited" label="IsDeposited" 
                options={{ 
                    SelectProps: { displayEmpty: true },
                    InputLabelProps: { shrink: true }
                }}/>
            <SelectInput
                source="status"
                choices={[
                    { id: 'Active', name: 'Active' },
                    { id: 'Posted', name: 'Posted' },
                    { id: 'Cancelled', name: 'Cancelled' },
                ]}
                className={classes.status}
            />
        </Filter>
    )
};

const ListBulkActionButtons = (props) => {

    
    return (
        <Fragment>
            <BulkDeleteButton {...props} undoable={false} confirmTitle="Confirm Bulk Delete"></BulkDeleteButton>
        </Fragment>
    )
};

const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} label="Add Collection"/>
            <RefreshButton />
        </TopToolbar>
    );
};

const useListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
    
});

const ListActionToolbar = ({ children, ...props }) => {
    const classes = useListActionToolbarStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

const PaymentEditButton = ({ record, basePath, ...props }) => {
    const { permissions } = usePermissions();
    
    return (
        <EditButton basePath={basePath} record={record} disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ? false : record.isPosted } />
    )
};

const PaymentDeleteButton = ({ record, basePath, ...props }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info');
        redirect('list', basePath);
        refresh();
    }

    return (
        <DeleteButton basePath={basePath} record={record} disabled={hasRole(permissions, "ROLE_SUPER_ADMIN") ? false : record.isPosted } onSuccess={onSuccessDelete} mutationMode="pessimistic" confirmTitle="Confirm Delete"/>
    )
}

const styles = {
    type: {  textAlign: 'center' },
    status: {  textAlign: 'center' },
    isDeposited: { width: '5%', textAlign: 'center' },
    action: { width: '10%'},
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    },
};

const useStyles = makeStyles(styles);

const PaymentCollection = (props) => {
    const classes = useStyles();

    const history = useHistory();

    const { 
        basePath,
    } = props;

    const handleClose = useCallback(() => {
        history.push('/payment-collections');
    }, [history]);

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Payment Collections"
                bulkActionButtons={false}
                actions={<ListActions/>}
                filters={<AccountFilter />}
                perPage={25}
                sort={{ field: 'collectedDate', order: 'DESC' }}>
                <Datagrid 
                    optimized
                    >
                    <TextField source="account.accountName" label="Account Name"/>
                    <TextField source="runner.runnerName" label="Runner" />
                    <DateField source="collectedDate" label="Date Collected" />
                    <TextField source="referenceNo" label="Reference No."/>
                    <TextField source="type" label="Type" cellClassName={classes.type} headerClassName={classes.type}/>
                    <NumberField source="paidAmount" label="Paid Amount"/>
                    <BooleanField source="isDeposited" label="Is Deposited" cellClassName={classes.isDeposited} headerClassName={classes.isDeposited} className={classes.alignCenter}/>
                    <TextField source="status" label="Status" cellClassName={classes.status} headerClassName={classes.status}/>
                    <ListActionToolbar >
                        <PaymentEditButton />
                        <PaymentDeleteButton/>
                    </ListActionToolbar>
                  
                </Datagrid>
            </List>
            <Route path="/payment-collections/create">
                {({ match }) => (
                    <Dialog
                        fullWidth
                        open={!!match}
                        maxWidth={'sm'}
                        onClose={handleClose}
                        scroll={'body'}
                        disableBackdropClick={true}
                    >
                        <DialogContent style={{padding: '0px'}}>
                            <PaymentCollectionCreate
                                onCancel={handleClose}
                                {...props}
                            />
                        </DialogContent>
                    
                    </Dialog>
                )}
            </Route>
            <Route path="/payment-collections/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create'
                    );

                    return (
                        <Dialog
                            fullWidth
                            open={isMatch}
                            maxWidth={'sm'}
                            onClose={handleClose}
                            scroll={'body'}
                            disableBackdropClick={true}
                        >
                            <DialogContent style={{padding: '0px'}}>
                                {isMatch ? (
                                    <PaymentCollectionEdit
                                        id={isMatch ? decodeURIComponent(match.params.id) : null}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : (
                                    null
                                )}
                            </DialogContent>
                        
                        </Dialog>
                    );
                }}
            </Route>
        </Fragment>
    )
}

export default PaymentCollection;
