import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Title, useNotify, useRedirect, fetchStart, fetchEnd } from 'react-admin';
import { useDataProvider, Loading, Error  } from 'react-admin';
import { 
    sanitizeEmptyValues,
    ReferenceInput,
    SelectInput,
    required,
    SaveButton,
    minLength,
    TextInput,
    Button,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    FormDataConsumer,
    Confirm,
    useCreate
} from 'react-admin';
import { Form, useForm  } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Typography, Box, Toolbar, Grid } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';

import TemplateButton from './TemplateButton';
import SmsLogsButton from './SmsLogsButton';
import ClearButton from './ClearButton';

const SendSMS = () => {

    const dataProvider = useDataProvider();
    const [apiInfo, setApiInfo] = useState({ MessagesLeft : 0});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState();
    const [title, setTitle] = useState('Confirm Send');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notify = useNotify();
    const dispatch = useDispatch();

    const getApiInfo = useCallback(() => {
        dataProvider.getOne('sms-summaries', { id: '/api/sms-summaries/PR-KENNE533012_9DVHA' })
        .then(({ data }) => {
            console.log(data);
            setApiInfo({ MessagesLeft: data.messagesLeft});
            setIsLoading(false);
        })
        .catch(error => {
            setError(error);
            setIsLoading(false);
        })
    }, [dataProvider]);

    useEffect(() => {
        getApiInfo();
    }, [getApiInfo]);

    const submit = values => {
        const title = 'Send To : ' + values.subscriber_ids.length + ' ' + ((values.subscriber_ids.length === 1) ? 'account' : 'accounts');
        setFormValues(values);
        setTitle(title);
        setOpen(true);
    };

    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
    };

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = useCallback(() => {
        const values = sanitizeEmptyValues( {}, formValues);

        const data = JSON.stringify({
            subscribers: values.subscriber_ids,
            messageContent: values.messageContent
        });

        setIsSubmitting(true);
        dataProvider.sendBulkSms(
            { data: data }
        )
        .then(({ data }) => {
            setOpen(false);
            notify('Successfully submitted for queue');
        })
        .catch(e => {
            console.log(e.status);
            notify(
                "A technical error occured. Please try again later.",
                "warning"
            );
            
        })
         .finally(() => {
            setIsSubmitting(false);
        });

        // const entrypoint = process.env.REACT_APP_API_ENTRYPOINT || '';
        // setIsSubmitting(true);
        // dispatch(fetchStart());
        // fetch(entrypoint + `/send-bulk-sms`, { 
        //     method: 'POST', 
        //     body: data,
        //     headers: { 
        //         Authorization: `Bearer ${localStorage.getItem("token")}`,
        //         'Content-Type': 'application/ld+json' 
        //     } 
        // })
        // .then(() => {
        //     setOpen(false);
        //     notify('Successfully submitted for queue');
        // })
        // .catch((e) => {
        //     notify('Opss! something went wrong.', 'warning')
        // })
        // .finally(() => {
        //     setIsSubmitting(false);
        //     dispatch(fetchEnd()); // stop the global loading indicator
        // });

        },
        [dataProvider, notify, formValues]
    );

    return (
        <>
            <Box display="flex" justifyContent="space-between" width="100%" style={{marginBottom: 10, marginTop: 10}}>
                <Typography variant="h6" gutterBottom>
                    Compose
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Available Credits : <b>{apiInfo.MessagesLeft}</b>
                </Typography>
            </Box>
           
        
            <Card>
                <Title title={'Send SMS'} />
                <CardContent>
                    <Form
                        onSubmit={submit}
                        mutators={{ ...arrayMutators }}
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        keepDirtyOnReinitialize
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <ReferenceInput required 
                                            filter={{isActive: true, pagination: false}}
                                            sort={{ field: 'createdAt', order: 'ASC' }} label="Select Network Leader" source="leader" reference="leaders" validate={[required()]}>
                                            <SelectInput fullWidth optionText="leaderName" />
                                        </ReferenceInput>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            formData.leader && (
                                            <ReferenceArrayInput 
                                                filter={{isActive: true, status: 'Confirmed', leader: formData.leader}}
                                                sort={{ field: 'createdAt', order: 'DESC' }}
                                                label="Send To :" 
                                                source="subscriber_ids" reference="accounts"
                                                resource="accounts"
                                                filterToQuery={searchText => ({ accountName: searchText })}
                                                validate={[required()]}>
                                                    <AutocompleteArrayInput fullWidth optionText="accountName" />
                                            </ReferenceArrayInput>
                                            )
                                        )}
                                        </FormDataConsumer>
                                       
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <TextInput fullWidth resettable source="messageContent" rows={5} rowsMax={15} multiline label="Type your message or Select from template" validate={[required(),minLength(3)]}/>
                                    </Grid>
                                    <Grid item xs={1} style={{ marginTop: 8 }}>
                                        <TemplateButton />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item style={{ marginTop: 16 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            label="Send Now"
                                            type="submit"
                                        >
                                            <SendIcon />
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}>
                                        <ClearButton/>
                                    </Grid>
                                    
                                    <Grid item style={{ marginTop: 16 }}>
                                        <SmsLogsButton />
                                    </Grid>
                                </Grid>
                                
                            </form>
                        )}
                    />
                </CardContent>
            </Card>

            <Confirm
                loading={isSubmitting}
                isOpen={open}
                title={title}
                content="Are you sure you want to Send SMS?"
                confirm="Yes"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    )
    
}

export default SendSMS
