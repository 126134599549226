import React from 'react'
import { 
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    Toolbar,
    SaveButton,
    ListButton,
    TopToolbar,
    ReferenceInput,
    SelectInput,
    email,
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import ChevronLeft from '@material-ui/icons/ChevronLeft';
 import { makeStyles } from '@material-ui/core/styles';
 
 const CustomActions = (props) => {
     const { basePath } = props;
     return (
         <TopToolbar>
             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
         </TopToolbar>
     )
 };
 
 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Created`, 'info');
         redirect('list', basePath);
         refresh();
     };

     const transform = data => ({
        ...data,
        status: 'Active'
    });

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}
                 transform={transform}/>
         </Toolbar>
     );
 
 };

 
 export const styles = {
    mobileNo: { display: 'inline-block' },
    mobileType: { display: 'inline-block', marginLeft: 32 },
    name: { width: 544 },
    email: { width: 544 },
    address: { maxWidth: 544 },
};

const useStyles = makeStyles(styles);

const SubscriberCreate = (props) => {

    const classes = useStyles(props);

    return (
        <Create title="Register Subscriber" {...props} actions={<CustomActions />}>
            <SimpleForm 
                toolbar={<CustomToolbar />}>
                <ReferenceInput autoFocus required filter={{isActive: true, pagination: false}} label="Group" source="groupId" reference="groups" validate={[required()]}>
                    <SelectInput optionText="groupName" />
                </ReferenceInput>
                <TextInput source="name" fullWidth formClassName={classes.name} label="Name" validate={[required()]}/>
                <TextInput source="email" fullWidth formClassName={classes.email} label="Email" validate={[email()]}/>
                <TextInput source="mobileNo" label="Mobile No." formClassName={classes.mobileNo}/>
                <SelectInput source="mobileType" resettable={true} choices={[
                    { id: 'PH', name: 'PH' },
                    { id: 'INT', name: 'INT' },
                ]} formClassName={classes.mobileType} />
                <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
            </SimpleForm>
        </Create>
    )
}

export default SubscriberCreate
