import React, { useEffect} from 'react'
import { 
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    minLength,
    Toolbar,
    SaveButton,
    ListButton,
    TopToolbar,
    DateInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import ChevronLeft from '@material-ui/icons/ChevronLeft';
 import { makeStyles } from '@material-ui/core/styles';
 import { Typography, Box } from '@material-ui/core';
 import forEach from 'lodash/forEach';

 const CustomActions = (props) => {
     const { basePath } = props;
     return (
         <TopToolbar>
             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
         </TopToolbar>
     )
 };
 
 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Added`, 'info');
         redirect('list', basePath);
         refresh();
     };

     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
 };

 const SectionTitle = ({ label }) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

const SanitizedBox = ({ fullWidth, basePath, ...props }) => <Box {...props} />;

const BankAccountField = ({record}) => {
    return (
        <>
            <span>({record.bankCode}) {record.accountNo} - {record.accountName}</span>
        </>
    )
};

const styles = {
    paymentDate: { display: 'inline-block', width: '25%' },
    modePayment: { display: 'inline-block',  width: '25%' },
    depositDate: { display: 'inline-block', width: '25%' },
    depositNo: { display: 'inline-block',  marginLeft: '1%', width: '25%' },
    bankAccount: { width: '51%' },
    remarks: { width: '51%' },
    attachment: { width: '51%' },

    account: { display: 'inline-block', width: '100%' },
    collectionDate: { display: 'inline-block', width: '25%' },
    amount: { display: 'inline-block',  marginLeft: '1%', width: '25%' },
    reference: { display: 'inline-block',  marginLeft: '1%', width: '25%' },
};


const useStyles = makeStyles(styles);

const PaymentCreate = (props) => {

    const classes = useStyles(props);

    const transform = data => {
        const paymentItems = [];
        forEach(data.paymentItems, (item) => {
            console.log(item);
            paymentItems.push({
                paymentDate: item.paymentDate,
                account: item.account,
                amount: item.amount,
                referenceNo: item.referenceNo,
                status: 'Active'
            });
        });

        data.paymentItems = paymentItems;
        return {
            ...data,
            status: 'Active',
        }
    };

    const validatePaymentCreation = (values) => {
        const errors = {};
        if(values.modePayment !== 'BNT') {
            if (!values.photoRefFile) {
                errors.photoRefFile = 'Required';
            }
        }
       
        return errors
    };

    return (
        <Create title="Add Payment Group" transform={transform} {...props} actions={<CustomActions />}>
            <TabbedForm 
                toolbar={<CustomToolbar />} validate={validatePaymentCreation} >
                <FormTab
                    label="Deposit Slip Info"
                >
                    {/* <DateInput 
                        fullWidth
                        source="paymentDate" 
                        label="Payment Date" 
                        validate={[required()]}
                        formClassName={classes.paymentDate}
                    /> */}
                    <SelectInput 
                        fullWidth
                        formClassName={classes.modePayment}
                        label="Mode"
                        source="modePayment" 
                        resettable={true} 
                        choices={[
                        { id: 'CSH', name: 'Cash' },
                        { id: 'CHK', name: 'Check' },
                        { id: 'BNT', name: 'Bank Transfer' }
                    ]} validate={[required()]}/>
                    <ReferenceInput required 
                        fullWidth
                        filter={{isActive: true, pagination: false}}
                        sort={{ field: 'createdAt', order: 'ASC' }}
                        abel="Select Bank Account" source="bankAccount" 
                        reference="bank-accounts" validate={[required()]}
                        formClassName={classes.bankAccount}>
                        <SelectInput optionText={<BankAccountField />} />
                    </ReferenceInput>
                    <DateInput 
                        fullWidth
                        source="depositDate" 
                        label="Deposit Date" 
                        validate={[required()]}
                        formClassName={classes.depositDate}
                    />
                    <TextInput 
                        fullWidth
                        source="depositNo" 
                        label="Deposit Slip No." 
                        validate={[required()]}
                        formClassName={classes.depositNo}
                    />
                    
                    
                    <ImageInput
                        formClassName={classes.attachment} 
                        source="photoRefFile" 
                        label="Attach deposit slip" 
                        accept="image/png, image/jpg, image/jpeg"
                        placeholder={<p>Drag 'n' drop image here, or click to select files</p>}>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <TextInput 
                        fullWidth
                        source="remarks" 
                        rowsMax={15}
                        rows={5} 
                        multiline 
                        label="Remarks" 
                        validate={minLength(3)}
                        formClassName={classes.remarks}
                    />
                    
                    {/* <SelectInput 
                        fullWidth
                        source="status" 
                        resettable={true} 
                        choices={[
                            { id: 'Active', name: 'Active' },
                            { id: 'Posted', name: 'Posted' },
                            { id: 'Cancelled', name: 'Cancelled' },
                        ]} 
                        validate={[required()]} 
                        formClassName={classes.status}
                    /> */}
                </FormTab>
                
                <FormTab
                    path="payment-details"
                    label="Payment Details"
                >
                    <ArrayInput source="paymentItems" label="Payment Details" style={{width: '100%'}}>
                        <SimpleFormIterator >
                            <ReferenceInput
                                validate={[required()]}
                                fullWidth
                                resettable
                                allowEmpty
                                label="Select account..."
                                filter={{isActive: true, status: 'Confirmed'}} 
                                source="account" reference="accounts"
                                filterToQuery={searchText => ({ accountName: searchText })}
                                formClassName={classes.account}
                            >
                                <AutocompleteInput
                                    optionText="accountName"
                                />
                            </ReferenceInput>
                            <DateInput 
                                fullWidth
                                source="paymentDate" 
                                label="Collection Date" 
                                validate={[required()]}
                                formClassName={classes.collectionDate}
                            />
                            <TextInput 
                                fullWidth
                                source="referenceNo" 
                                label="OR No." 
                                validate={[required()]}
                                formClassName={classes.reference}
                            />
                            <NumberInput 
                                validate={[required()]}
                                fullWidth
                                source="amount" 
                                label="Amount"
                                formClassName={classes.amount}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                
                
            </TabbedForm>
        </Create>
    )
}

export default PaymentCreate
