import React from 'react'
import {
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useShowController,
    ShowContextProvider,
    NumberField,
    SelectField,
    BooleanField 
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ShowDialog from './ShowDialog';

const useStyles = makeStyles({
    
    leader: { display: 'inline-block', width: '49%' },
    runner: { display: 'inline-block', marginLeft: '2%', width: '49%' },
    collectedDate: { display: 'inline-block',  width: '49%' },
    type: { display: 'inline-block', marginLeft: '2%', width: '49%' },
    referenceNo: { display: 'inline-block', width: '49%' },
    paidAmount: { display: 'inline-block', marginLeft: '2%', width: '49%' },
});


const PaymentCollectionShow = ({ onCancel, ...props}) => {
    console.log(props);
    const classes = useStyles();
    const controllerProps  = useShowController(props);

    return (
        <ShowDialog onCancel={onCancel} title="View Payment Collection" >
             <ShowContextProvider value={controllerProps}>
                <SimpleShowLayout
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    version={controllerProps.version}
                    resource={controllerProps.resource}
                >
                    <TextField source="leader.leaderName" label="Network Leader"/>
                    <TextField source="runner.runnerName" label="Runner"/>
                    <TextField source="account.accountName" label="Account Name"/>
                    <DateField source="collectedDate" label="Date Collected"/>
                    <TextField source="referenceNo" label="ReferenceNo"/>
                    <SelectField source="type" choices={[
                        { id: 'CSH', name: 'Cash' },
                        { id: 'CHK', name: 'Check' },
                        { id: 'BNT', name: 'Bank Transfer' },
                    ]} />
                    <NumberField source="paidAmount" label="Paid Amount"/>
                    <BooleanField source="isDeposited" label="IsDeposited"/>
                    <DateField source="deposit.depositedDate" label="Date Deposited"/>
                    <BooleanField source="isPosted" label="IsPosted"/>
                    <DateField source="postedDate" label="Date Posted"/>
                </SimpleShowLayout>
            </ShowContextProvider>
        </ShowDialog>
    )
}

export default PaymentCollectionShow
