import React from 'react';
import { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    SearchInput
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';


const RunnerFilter = props => (
    <Filter {...props}>
        <SearchInput placeholder="Search Runner Name" source="runnerName" alwaysOn/>
    </Filter>
);

const ListActions = ({basePath}) => {
    return (
        <TopToolbar>
            <CreateButton basePath={basePath} />
            <RefreshButton />
        </TopToolbar>
    );
};

export const styles = {
    isActive: { width: '10%', textAlign: 'center' },
    name: { width: '30%' },
    remarks: { width: '30%'},
    username: { width: '10%'},
    alignCenter: {
        alignItems:'center', 
        justifyContent: 'center'
    }
};

const useStyles = makeStyles(styles);
const RunnerList = (props) => {
    const { 
        basePath
    } = props;

    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccessDelete = () => {
        notify(`Successfully Deleted.`, 'info', {},true);
        redirect('list', basePath);
        refresh();
    }

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Runners"
                actions={<ListActions/>}
                filters={<RunnerFilter />}
                perPage={25}
                sort={{ field: 'id', order: 'DESC' }}>
                <Datagrid>
                    <TextField source="runnerName" label="Runner Name" cellClassName={classes.runnerName}/>
                    <TextField source="remarks" label="Remarks" cellClassName={classes.remarks}/>
                    <TextField source="username" label="Username" cellClassName={classes.username}/>
                    <BooleanField source="isActive" label="IsActive" cellClassName={classes.isActive} headerClassName={classes.isActive} className={classes.alignCenter}/>
                    <EditButton />
                    <DeleteButton onSuccess={onSuccessDelete}/>
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default RunnerList
