import React from 'react'
import { 
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    Toolbar,
    SaveButton,
    useCreateController,
    minLength,
    CreateContextProvider,
    DateInput,
    ReferenceInput,
    SelectInput,
    NumberInput
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 
 import FromDialog from './FormDialog';

 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Added`, 'info');
         redirect('list', basePath);
         refresh();
     };
     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
};

const ExpenseCreate = ({ onCancel, ...props}) => {

    const controllerProps  = useCreateController(props);
 
    return (
        <FromDialog onCancel={onCancel} title="Add Expense">
            <CreateContextProvider value={controllerProps}>
                <SimpleForm 
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    saving={controllerProps.saving}
                    version={controllerProps.version}
                    redirect={controllerProps.redirect}
                    resource={controllerProps.resource}
                    toolbar={<CustomToolbar />}
                >

                    <DateInput fullWidth source="expenseDate" label="Date" validate={[required()]} />
                    <TextInput fullWidth source="referenceNo" label="Reference No" />
                    <ReferenceInput required 
                        fullWidth
                        filter={{isActive: true, pagination: false}}
                        sort={{ field: 'createdAt', order: 'ASC' }} label="Select Category" source="category" reference="expense-categories" validate={[required()]}>
                        <SelectInput optionText="catName" />
                    </ReferenceInput>
                    <TextInput fullWidth source="description" label="Decription" validate={minLength(3)}/>
                    <NumberInput fullWidth source="amount" label="Amount" validate={[required()]}/>
                </SimpleForm>
            </CreateContextProvider>
        </FromDialog>
    )
}

export default ExpenseCreate
