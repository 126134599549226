import React from 'react';
import { Fragment, cloneElement } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton, 
    DeleteButton,
    TextInput,
    TopToolbar,
    CreateButton,
    RefreshButton,
    Filter,
    BooleanField,
    EmailField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useListContext,
    NullableBooleanInput,
    SearchInput,
    SelectInput,
    NumberField,
    DateInput
} from 'react-admin';
import { useNotify, useRefresh, useRedirect } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment  } from '@material-ui/core';

const useFilterStyles = makeStyles({
    status: { width: 150 },
    accountName: { width: 350 }
});

const CustomFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
            <ReferenceInput
                alwaysOn
                filter={{isActive: true}} 
                label="Account Name"
                source="account" reference="accounts"
                filterToQuery={searchText => ({ accountName: searchText })}
                sort={{ field: 'id', order: 'ASC' }}
                className={classes.accountName}>
                <AutocompleteInput optionText="accountName" resettable/>
            </ReferenceInput>

            <DateInput
                label="Payment Date"
                source="payment.paymentDate"
            />
            <TextInput
                resettable
                source="payment.depositNo"
                label="Deposit No."
            />
            <DateInput
                label="Deposit Date"
                source="payment.depositDate"
            />
            <SelectInput
                source="status"
                choices={[
                    { id: 'Active', name: 'Active' },
                    { id: 'Posted', name: 'Posted' },
                    { id: 'Cancelled', name: 'Cancelled' },
                ]}
                className={classes.status}
            />
    </Filter>
    )
};


const ListActions = (props) => {

    const {
        filters
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar>
             {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <RefreshButton />
        </TopToolbar>
    );
};

const EmailMobileField = (props) => {

    return (
        <>
        <div>
            <TextField source="accountMobile" {...props}/>
        </div>
        
        <div>
            <EmailField  source="accountEmail" {...props}/>
        </div>
        </>
    )
};

EmailMobileField.defaultProps = { label: 'Email/Mobile' };

export const styles = {
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    status: { width: '10%', textAlign: 'center' },
    amount: { width: '15%' },
    paymentDate: { width: '15%', textAlign: 'center' },
    depositDate: { width: '15%', textAlign: 'center' },
    depositNo: { width: '15%' },
};

const useStyles = makeStyles(styles);
const PaymentItemList = (props) => {
    const { 
        selectedRow
    } = props;

    const classes = useStyles();

    return (
        <Fragment>
            <List 
                {...props}
                empty={false}
                exporter={false}
                title="Collection Inquiry"
                bulkActionButtons={false}
                actions={<ListActions/>}
                filters={<CustomFilter />}
                perPage={25}
                sort={{ field: 'payment.paymentDate', order: 'DESC' }}>
                <Datagrid 
                    optimized 
                    classes={{
                        headerRow: classes.headerRow,
                        headerCell: classes.headerCell,
                        rowCell: classes.rowCell,
                    }}
                    >

                    <DateField source="payment.depositDate" label="Deposit Date" cellClassName={classes.depositDate} headerClassName={classes.depositDate}/>
                    <TextField source="payment.depositNo" label="Deposit Slip No" cellClassName={classes.depositNo} />
                    <TextField source="account.accountName" label="Account Name"/>
                    <DateField source="paymentDate" label="Collection Date" cellClassName={classes.paymentDate} headerClassName={classes.paymentDate}/>
                    <NumberField source="amount" label="Amount" cellClassName={classes.amount}/>
                    <TextField source="payment.status" label="Status" cellClassName={classes.status} headerClassName={classes.depositDate}/>
                    
                </Datagrid>
            </List>
        </Fragment>
    )
}

export default PaymentItemList
