import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import { useMediaQuery, Box } from '@material-ui/core';
import {
    DashboardMenuItem,
    MenuItemLink,
    usePermissions 
} from 'react-admin';

import SubMenu from './SubMenu';

import HelpIcon from '@material-ui/icons/Help';
import PublicIcon from '@material-ui/icons/Public';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ForumIcon from '@material-ui/icons/Forum';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import NoteIcon from '@material-ui/icons/Note';
import GroupIcon from '@material-ui/icons/Group';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { hasRole } from '../security/helper';

const Menu = ({ onMenuClick, logout, dense = false }) => {
    const [state, setState] = useState({
        menuUtilities: true,
        menuSms: true,
        menuExpenses: true,
        menuDeposit: true,
        menuInquiries: true
    });

    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const { permissions } = usePermissions();

    return (
        <Box mt={1}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/accounts`}
                primaryText={`Account`}
                leftIcon={<AccountCircleIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuInquiries')}
                isOpen={state.menuInquiries}
                sidebarIsOpen={open}
                name="Inquiries"
                icon={<FindReplaceIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/inquiries/payment-collections`}
                    primaryText={`Payment Collection`}
                    leftIcon={<ArrowRightIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuDeposit')}
                isOpen={state.menuDeposit}
                sidebarIsOpen={open}
                name="Deposit Slip"
                icon={<ReceiptIcon />}
                dense={dense}
            >
                
                <MenuItemLink
                    to={`/deposits`}
                    primaryText={`List`}
                    leftIcon={<ListAltIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                {
                    (hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN")) &&
                        <MenuItemLink
                            to={`/deposit-posting`}
                            primaryText={`Review / Posting`}
                            leftIcon={<DoneAllIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                }
            </SubMenu>
          
            
            <MenuItemLink
                to={`/payment-collections`}
                primaryText={`Payment Collection`}
                leftIcon={<PaymentIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {/* <MenuItemLink
                to={`/payments`}
                primaryText={`Collections`}
                leftIcon={<PaymentIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/payment-items`}
                primaryText={`Collection Inquiry`}
                leftIcon={<ReceiptIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense} 
            /> */}
            {
                (hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN")) &&
                    <>
                        <MenuItemLink
                            to={`/expenses`}
                            primaryText={`Expenses`}
                            leftIcon={<AttachMoneyIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />

                        
                    </>
            }
            <MenuItemLink
                to={`/reports`}
                primaryText={`Report`}
                leftIcon={<AssessmentIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {
                (hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN")) &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuUtilities')}
                        isOpen={state.menuUtilities}
                        sidebarIsOpen={open}
                        name="Utilities"
                        icon={<TuneIcon />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/leaders`}
                            primaryText={`Leader`}
                            leftIcon={<EmojiPeopleIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        
                        <MenuItemLink
                            to={`/runners`}
                            primaryText={`Runner`}
                            leftIcon={<DirectionsRunIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/offerings`}
                            primaryText={`Offering`}
                            leftIcon={<LocalOfferIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/bank-accounts`}
                            primaryText={`Bank Account`}
                            leftIcon={<AccountBalanceIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/expense-categories`}
                            primaryText={`Expense Category`}
                            leftIcon={<AttachMoneyIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {/* <MenuItemLink
                            to={`/countries`}
                            primaryText={`Country`}
                            leftIcon={<PublicIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={false}
                        /> */}
                        
                    </SubMenu>
            }

            {
                (hasRole(permissions, "ROLE_SUPER_ADMIN") ||  hasRole(permissions, "ROLE_ADMIN")) &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuSms')}
                        isOpen={state.menuSms}
                        sidebarIsOpen={open}
                        name="SMS & Email"
                        icon={<ForumIcon />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/send-sms`}
                            primaryText={`Send SMS`}
                            leftIcon={<SmsIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/send-email`}
                            primaryText={`Send Email`}
                            leftIcon={<EmailIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/templates`}
                            primaryText={`Template`}
                            leftIcon={<NoteIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {/* <MenuItemLink
                            to={`/groups`}
                            primaryText={`Group`}
                            leftIcon={<GroupIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />

                        <MenuItemLink
                            to={`/subscribers`}
                            primaryText={`Subscriber`}
                            leftIcon={<SupervisedUserCircleIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        /> */}
                    </SubMenu>
            }
            
            {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={'Configuration'}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </Box>
    );
};

export default Menu;