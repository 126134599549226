import React from 'react'
import { 
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    NumberInput,
    Toolbar,
    SaveButton,
    ListButton,
    TopToolbar
 } from 'react-admin'
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import ChevronLeft from '@material-ui/icons/ChevronLeft';
 
 
 const CustomActions = (props) => {
     const { basePath } = props;
     return (
         <TopToolbar>
             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
         </TopToolbar>
     )
 };
 
 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Created`, 'info');
         redirect('list', basePath);
         refresh();
     };
 
     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
 };

 
const OfferingCreate = (props) => {
    return (
        <Create title="Add Offering" {...props} actions={<CustomActions />}>
            <SimpleForm 
                toolbar={<CustomToolbar />}>
                <TextInput style={{width:'50%'}} autoFocus source="offeringName" rowsMax={15} multiline label="Name" validate={[required()]}/>
                <NumberInput source="offeringValue" label="Amount" validate={required()}/>
                <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
            </SimpleForm>
        </Create>
    )
}

export default OfferingCreate
