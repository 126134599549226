import React from 'react'
import { 
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    minLength,
    Toolbar,
    SaveButton,
    ListButton,
    TopToolbar,
    SelectInput,
    FormDataConsumer 
 } from 'react-admin'
 import RichTextInput from 'ra-input-rich-text';
 import { useNotify, useRefresh, useRedirect } from 'react-admin';
 import ChevronLeft from '@material-ui/icons/ChevronLeft';
import './template.css';
 
 const CustomActions = (props) => {
     const { basePath } = props;
     return (
         <TopToolbar>
             <ListButton basePath={basePath} label="Back to list" icon={<ChevronLeft />}/>
         </TopToolbar>
     )
 };
 
 const CustomToolbar  = (props) => {
     
     const { 
         basePath,
         pristine,
     } = props;
 
     const notify = useNotify();
     const refresh = useRefresh();
     const redirect = useRedirect();
 
     const onSuccess = ({data}) => {
         notify(`Successfully Created`, 'info');
         redirect('list', basePath);
         refresh();
     };
     return (
         <Toolbar {...props}>
             <SaveButton 
                 disabled={pristine}
                 onSuccess={onSuccess}/>
         </Toolbar>
     );
 
 };

 
const TemplateCreate = (props) => {

    return (
        <Create title="Add Template" {...props} actions={<CustomActions />}>
            <SimpleForm 
                toolbar={<CustomToolbar />}>
                
                <TextInput style={{width:'50%'}} autoFocus source="label" rowsMax={15} multiline label="Template Name" validate={[required()]}/>
                <SelectInput
                    source="type"
                    label="Type"
                    choices={[
                        { id: 'SMS', name: 'SMS' },
                        { id: 'EMAIL', name: 'EMAIL' },
                    ]}
                    validate={[required()]}
                />

                
                <FormDataConsumer>
                {({ formData, ...rest }) => formData.type === 'EMAIL' &&
                    <RichTextInput fullWidth={false} 
                    source="templateContent" label="Template Content" validate={[required(), minLength(3)]}
                    options={{
                        placeholder: 'Template Content',
                    }}/>
                }
                </FormDataConsumer>
                <FormDataConsumer>
                {({ formData, ...rest }) => formData.type === 'SMS' &&
                    <TextInput style={{width:'50%'}} source="templateContent" rows={10} rowsMax={15} multiline label="Template Content" validate={[required(), minLength(3)]}/>
                }
                </FormDataConsumer>
                
                <BooleanInput source="isActive" label="IsActive" defaultValue={true}/>
            </SimpleForm>
        </Create>
    )
}

export default TemplateCreate
