import * as React from 'react';
import NumberFormat from 'react-number-format';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CardWithIcon from './CardWithIcon';


const TotalConfirmedAccount = ({ value }) => {
    return (
        <CardWithIcon
            icon={AccountCircleIcon}
            title={'Confirmed Accounts'}
            subtitle={<NumberFormat value={value} displayType={'text'} thousandSeparator={true}/>}
            to=""
        />
    );
};

export default TotalConfirmedAccount;